<template>
  <div>
    <div
      class="col-12"
      style="
        position: fixed;
        justify-content: end;
        display: flex;
        top: 94px;
        width: 5%;
        right: -15px;
        z-index: 1;
      ">
      <div>
        <div>
          <button
            v-on:click="showOrderBookTable()"
            title="Order Book"
            class="save-btn"
            style="
              width: 40px !important;
              margin-top: 3px !important;
              cursor: pointer;
            "
            type="button">
            OB
          </button>
        </div>
        <div>
          <button
            v-on:click="showTradeBookTable()"
            title="Trade Book"
            class="save-btn side-btn"
            style="
              width: 40px !important;
              margin-top: 3px !important;
              cursor: pointer;
            "
            type="button">
            TB
          </button>
        </div>
        <div>
          <button
            v-on:click="showPositionReportTable()"
            title="Position Book"
            class="save-btn side-btn"
            style="
              width: 40px !important;
              margin-top: 3px !important;
              cursor: pointer;
            "
            type="button">
            PR
          </button>
        </div>
      </div>
    </div>
    <div class="container form-signin3">
      <div class="row">
        <div
          class="col-md-12 form"
          style="padding-left: 50px; padding-right: 50px">
          <form
            class="form-inline transparent-form border-page order-form-content">
            <div class="col-lg-12 col-sm-12 row">
              <div class="col-lg-2 col-sm-6 mb-2">
                <label class="col-form-label ml-1 mt-0"> Script Name </label>

                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="instrument_token"
                  class="register-select"
                  placeholder="SCRIPT"
                  v-model="detail.instrument_token"
                  :options="scriptList"
                  searchable="true"
                  @select="getScriptExpire(detail.instrument_token)"
                  @change="emptyExpire()" />
              </div>
              <div class="col-lg-2 col-sm-6 mb-2">
                <label class="col-form-label ml-1 mt-0"> Expire </label>
                <Multiselect
                  mode="single"
                  trackBy="expiry"
                  label="expiry"
                  valueProp="instrument_token"
                  class="register-select"
                  placeholder="EXPIRY"
                  v-model="detail.expire_token"
                  :options="expireList"
                  searchable="true" />
              </div>
              <div class="col-lg-1 col-sm-6 mb-2 mt-4">
                <button
                  class="save-btn"
                  style="width: 70px !important; margin-top: 0 !important"
                  id="save-button"
                  type="button"
                  v-on:click="addToWishlist()">
                  ADD
                </button>
              </div>
              <div class="col-lg-2 col-sm-6 mb-2 mt-4">
                <Field
                  type="text"
                  class="form-control"
                  name="ledger_balance"
                  placeholder="SEARCH"
                  v-model="keyword"
                  v-on:keyup="getList()" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page sticky-table-head">
          <table
            id="table"
            style="margin-top: 0px; margin-bottom: 0px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered-new product-list table-hover">
            <thead>
              <tr style="cursor: pointer">
                <th class="head">
                  <i
                    class="fa fa-caret-down"
                    style="cursor: pointer; font-size: 17px; margin-right: 4px"
                    v-if="isShowNse"
                    v-on:click="showNseTable()"></i>
                  <i
                    class="fa fa-caret-up"
                    style="cursor: pointer; font-size: 17px; margin-right: 4px"
                    v-if="!isShowNse"
                    v-on:click="showNseTable()"></i>
                  <span v-on:click="showNseTable()"> COMEX </span>
                  <span
                    class="head sorting"
                    id="id-nse"
                    style="left: 103px; top: 4px"
                    v-on:click="sorting('name', 'id-nse', 'COMEX')">
                  </span>
                </th>
                <th class="head">Bid Rate</th>
                <th class="head">Ask Rate</th>
                <th class="head">LTP</th>
                <th class="head">Change(%)</th>
                <th class="head">Net Change</th>
                <th class="head">High</th>
                <th class="head">Low</th>
                <th class="head">Open</th>
                <th class="head">Close</th>
                <th class="head">Remove</th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr
                v-for="(data, index) in comex_wishlist"
                :key="index"
                :style="isShowNse ? '' : 'display:none !important;'">
                <td
                  style="text-align: initial !important"
                  v-on:click="setBuySellDetail(data, index, 'COMEX')"
                  @dblclick="
                    setBuySellDetailDoubleClick($event, data, index, 'COMEX')
                  "
                  class="body-new"
                  :style="
                    data.ba
                      ? 'background-color:orange;'
                      : data.is_highlight
                      ? 'background-color:grey;'
                      : ''
                  ">
                  {{
                    data.name + " " + $helperService.getCustomDate(data.expiry)
                  }}
                  &nbsp; &nbsp; &nbsp;
                  <i
                    style="font-size: 11px"
                    class="fa fa-suitcase"
                    aria-hidden="true"
                    v-if="
                      Number(data.total_buy_quantity) -
                        Number(data.total_sell_quantity) !=
                      0
                    "></i>

                  <span
                    style="font-size: 11px"
                    v-if="
                      Number(data.total_buy_quantity) -
                        Number(data.total_sell_quantity) !=
                      0
                    ">
                    ({{
                      Number(
                        data.total_buy_quantity ? data.total_buy_quantity : 0
                      ) -
                      Number(
                        data.total_sell_quantity ? data.total_sell_quantity : 0
                      )
                    }})</span
                  >
                </td>
                <td
                  v-on:click="setBuySellDetail(data, index, 'COMEX')"
                  @dblclick="
                    setBuySellDetailDoubleClick($event, data, index, 'COMEX')
                  "
                  class="body-new"
                  :style="
                    data.ba
                      ? 'background-color:orange;'
                      : data.is_highlight
                      ? 'background-color:grey;'
                      : ''
                  "
                  style="padding: 0px !important">
                  <div
                    style="padding: 0.3rem"
                    :style="
                      data.ba
                        ? 'background-color:orange;'
                        : 'background-color:' + data.bc
                    ">
                    {{ data.b?.toFixed(2) }}
                  </div>
                </td>
                <td
                  v-on:click="setBuySellDetail(data, index, 'COMEX')"
                  @dblclick="
                    setBuySellDetailDoubleClick($event, data, index, 'COMEX')
                  "
                  class="body-new"
                  :style="
                    data.ba
                      ? 'background-color:orange;'
                      : data.is_highlight
                      ? 'background-color:grey;'
                      : ''
                  "
                  style="padding: 0px !important">
                  <div
                    style="padding: 0.3rem"
                    :style="
                      data.ba
                        ? 'background-color:orange;'
                        : 'background-color:' + data.ac
                    ">
                    {{ data.a?.toFixed(2) }}
                  </div>
                </td>
                <td
                  v-on:click="setBuySellDetail(data, index, 'COMEX')"
                  @dblclick="
                    setBuySellDetailDoubleClick($event, data, index, 'COMEX')
                  "
                  class="body-new"
                  :style="
                    data.ba
                      ? 'background-color:orange;'
                      : data.is_highlight
                      ? 'background-color:grey;'
                      : ''
                  "
                  style="padding: 0px !important">
                  <div
                    style="padding: 0.3rem"
                    :style="
                      data.ba
                        ? 'background-color:orange;'
                        : 'background-color:' + data.ltpc
                    ">
                    {{ data.ltp?.toFixed(2) }}
                  </div>
                </td>
                <td
                  v-on:click="setBuySellDetail(data, index, 'COMEX')"
                  @dblclick="
                    setBuySellDetailDoubleClick($event, data, index, 'COMEX')
                  "
                  class="body-new"
                  :style="
                    data.ba
                      ? 'background-color:orange;'
                      : data.is_highlight
                      ? 'background-color:grey;'
                      : ''
                  ">
                  <i
                    v-show="data?.ch > 0"
                    class="fa fa-caret-up"
                    style="
                      cursor: pointer;
                      color: blue;
                      font-size: 17px;
                      margin-right: 4px;
                    "></i>
                  <i
                    v-show="data?.ch < 0"
                    class="fa fa-caret-down"
                    style="
                      cursor: pointer;
                      color: red;
                      font-size: 17px;
                      margin-right: 4px;
                    "></i>
                  {{ data?.ch?.toFixed(2) }}
                </td>
                <td
                  v-on:click="setBuySellDetail(data, index, 'COMEX')"
                  @dblclick="
                    setBuySellDetailDoubleClick($event, data, index, 'COMEX')
                  "
                  class="body-new"
                  :style="
                    data.ba
                      ? 'background-color:orange;'
                      : data.is_highlight
                      ? 'background-color:grey;'
                      : ''
                  ">
                  {{ data?.n?.toFixed(2) }}
                </td>
                <td
                  v-on:click="setBuySellDetail(data, index, 'COMEX')"
                  @dblclick="
                    setBuySellDetailDoubleClick($event, data, index, 'COMEX')
                  "
                  class="body-new"
                  :style="
                    data.ba
                      ? 'background-color:orange;'
                      : data.is_highlight
                      ? 'background-color:grey;'
                      : ''
                  ">
                  {{ data.h?.toFixed(2) }}
                </td>
                <td
                  v-on:click="setBuySellDetail(data, index, 'COMEX')"
                  @dblclick="
                    setBuySellDetailDoubleClick($event, data, index, 'COMEX')
                  "
                  class="body-new"
                  :style="
                    data.ba
                      ? 'background-color:orange;'
                      : data.is_highlight
                      ? 'background-color:grey;'
                      : ''
                  ">
                  {{ data.l?.toFixed(2) }}
                </td>
                <td
                  v-on:click="setBuySellDetail(data, index, 'COMEX')"
                  @dblclick="
                    setBuySellDetailDoubleClick($event, data, index, 'COMEX')
                  "
                  class="body-new"
                  :style="
                    data.ba
                      ? 'background-color:orange;'
                      : data.is_highlight
                      ? 'background-color:grey;'
                      : ''
                  ">
                  {{ data.o?.toFixed(2) }}
                </td>
                <td
                  v-on:click="setBuySellDetail(data, index, 'COMEX')"
                  @dblclick="
                    setBuySellDetailDoubleClick($event, data, index, 'COMEX')
                  "
                  class="body-new"
                  :style="
                    data.ba
                      ? 'background-color:orange;'
                      : data.is_highlight
                      ? 'background-color:grey;'
                      : ''
                  ">
                  {{ data.c?.toFixed(2) }}
                </td>
                <td
                  v-on:click="setBuySellDetail(data, index, 'COMEX')"
                  @dblclick="
                    setBuySellDetailDoubleClick($event, data, index, 'COMEX')
                  "
                  class="body-new"
                  :style="
                    data.ba
                      ? 'background-color:orange;'
                      : data.is_highlight
                      ? 'background-color:grey;'
                      : ''
                  ">
                  <a
                    v-on:click="removeFromWishlist(data.id)"
                    class="fa fa-trash text-danger"
                    style="cursor: pointer"
                    title="Delete"></a>
                  <span style="font-size: 11px; margin-left: 10px">{{
                    $helperService.getCustomDate(data.expire_new)
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

     </div>

    <!-- ===================================== -->
    <div
      id="dashfoot"
      v-if="is_show_buy_sell_section"
      class="buy-sell-section ng-scope"
      :style="
        buySellObj.order_action == 'BUY'
          ? 'background-color:rgb(31, 88, 204)'
          : 'background-color:rgb(161, 51, 41)'
      ">
      <Form ref="buySell" @submit="submit()">
        <div class="row" style="margin-left: 0px; margin-right: 0px">
          <div class="col-12 col-lg-3 col-xl-3">
            <div class="form-group row">
              <div class="col-sm-12">
                <br />
                <Field
                  type="text"
                  class="form-control"
                  name="buySellObjName"
                  placeholder="Script"
                  disabled
                  v-model="buySellObj.trading_symbol" />
              </div>
            </div>
          </div>

          <div
            v-if="buySellObj.order_action == 'SELL'"
            :style="'background-color:' + buySellObj.bc + '! important'"
            class="form-group col-sm-1 text-center trade-font-size-other trade-price red">
            <label class="col-form-label" style="color: white !important"
              >Bid Rate </label
            ><br />
            <span class="ng-binding" style="color: white !important">{{
              buySellObj.b?.toFixed(2)
            }}</span>
          </div>
          <div
            v-if="buySellObj.order_action == 'BUY'"
            :style="'background-color:' + buySellObj.ac + '! important'"
            class="form-group col-sm-1 text-center trade-font-size-other trade-price red">
            <label class="col-form-label" style="color: white !important"
              >Ask Rate </label
            ><br />
            <span class="ng-binding" style="color: white !important">{{
              buySellObj.a?.toFixed(2)
            }}</span>
          </div>
          <div
            :style="'background-color:' + buySellObj.ltpc + '! important'"
            class="form-group col-sm-1 text-center trade-font-size-other trade-price red">
            <label class="col-form-label" style="color: white !important"
              >LTP</label
            ><br />
            <span class="ng-binding" style="color: white !important">{{
              buySellObj.ltp?.toFixed(2)
            }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price">
            <label class="col-form-label">Change %</label><br /><span>{{
              buySellObj.ch?.toFixed(2)
            }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price">
            <label class="col-form-label">Net Chg</label><br />
            <span class="ng-binding">{{ buySellObj.n?.toFixed(2) }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price">
            <label class="col-form-label">High</label><br />
            <span class="ng-binding">{{ buySellObj.h?.toFixed(2) }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price">
            <label class="col-form-label">Low</label><br />
            <span class="ng-binding">{{ buySellObj.l?.toFixed(2) }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price">
            <label class="col-form-label">Open</label><br />
            <span class="ng-binding">{{ buySellObj.o?.toFixed(2) }}</span>
          </div>
          <div
            class="form-group col-sm-1 text-center trade-font-size-other trade-price">
            <label class="col-form-label">Close</label><br />
            <span class="ng-binding">{{ buySellObj.c?.toFixed(2) }}</span>
          </div>
        </div>
        <div class="col-12 row">
          <div class="col-sm-2 mt-2">
            <div class="custom-control custom-radio d-block float-left">
              <Field
                v-model="buySellObj.order_action"
                type="radio"
                value="BUY"
                id="gross_partnership"
                name="net_gross_partner"
                class="custom-control-input ng-pristine ng-untouched ng-valid"
                checked="checked"
                style="height: unset !important" />
              <label for="gross_partnership" class="custom-control-label"
                >Buy</label
              >
            </div>
            <div class="custom-control custom-radio d-block float-right">
              <Field
                v-model="buySellObj.order_action"
                type="radio"
                value="SELL"
                id="net_partnership"
                name="net_gross_partner"
                class="custom-control-input ng-pristine ng-untouched ng-valid"
                style="height: unset !important" />
              <label for="net_partnership" class="custom-control-label"
                >Sell</label
              >
            </div>
            <Field
              name="order_type"
              class="register-select mt-2"
              as="select"
              v-model="buySellObj.order_type"
              rules="required: order type"
              :validateOnInput="true">
              <option value="" disabled>Order Type</option>
              <option value="MARKET">Market</option>
              <option value="LIMIT">Limit</option>
              <option value="INTRADAY">Intraday</option>
              <option value="STOPLOSS">Stoploss</option>
            </Field>
            <ErrorMessage name="order_type" class="text-white" />
          </div>
          <div class="col-sm-2 mt-1" v-if="buySellObj.order_type != 'INTRADAY'">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Lot</label
            ><br />
            <Field
              id="lotInput"
              type="number"
              class="form-control"
              name="Lot"
              min="1"
              v-model="buySellObj.lot_size"
              v-on:keyup="updateQuantity(buySellObj.lot_size)"
              placeholder="Lot"
              rules="required:lot"
              autocomplete="off"
              :validateOnInput="true"
              v-on:keypress="
                buySellObj.exchange != 'MCX'
                  ? $helperService.allowDecimalValue($event)
                  : $helperService.allowWholeNumber($event)
              " />
          </div>
          <div class="col-sm-2 mt-1" v-if="buySellObj.order_type != 'INTRADAY'">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Qty</label
            ><br />
            <Field
              type="number"
              class="form-control"
              min="1"
              name="Qty"
              :style="buySellObj.exchange == 'MCX' ? 'cursor: no-drop;' : ''"
              v-model="buySellObj.quantity"
              autocomplete="off"
              v-on:keyup="updateLot(buySellObj.lot_size)"
              placeholder="Qty"
              rules="required:quantity"
              :validateOnInput="true"
              :disabled="buySellObj.exchange == 'MCX'"
              v-on:keypress="$helperService.allowDecimalValue($event)" />
          </div>
          <div
            class="col-sm-2 mt-1"
            v-if="
              buySellObj.order_type == 'MARKET' &&
              buySellObj.order_action == 'BUY'
            ">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Price</label
            ><br />
            <Field
              type="text"
              class="form-control"
              name="Price"
              placeholder="Price"
              :disabled="buySellObj.order_type == 'MARKET'"
              v-model="buySellObj.a"
              rules="required:price"
              autocomplete="off"
              :validateOnInput="true"
              v-on:keypress="$helperService.allowDecimalValue($event)" />
            <ErrorMessage name="Price" class="text-white" />
          </div>
          <div
            class="col-sm-2 mt-1"
            v-if="
              buySellObj.order_type == 'MARKET' &&
              buySellObj.order_action == 'SELL'
            ">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Price</label
            ><br />
            <Field
              type="text"
              class="form-control"
              name="marketPrice"
              placeholder="Price"
              :disabled="buySellObj.order_type == 'MARKET'"
              v-model="buySellObj.b"
              rules="required:price"
              autocomplete="off"
              :validateOnInput="true"
              v-on:keypress="$helperService.allowDecimalValue($event)" />
            <ErrorMessage name="Price" class="text-white" />
          </div>
          <div class="col-sm-2 mt-1" v-if="buySellObj.order_type == 'LIMIT'">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Price</label
            ><br />
            <Field
              type="text"
              class="form-control"
              name="limitPrice"
              placeholder="Price"
              v-model="buySellObj.price"
              rules="required:price"
              :validateOnInput="true"
              autocomplete="off"
              v-on:keyup="checkMultiple(buySellObj.price, buySellObj.tick_size)"
              v-on:keypress="$helperService.allowDecimalValue($event)" />
            <ErrorMessage name="Price" class="text-white" />
            <p v-if="isTickSize" style="font-size: 13px; color: white">
              Price Should be in multiple of {{ buySellObj.tick_size }}
            </p>
          </div>
          <div class="col-sm-2 mt-1" v-if="buySellObj.order_type == 'STOPLOSS'">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Price</label
            ><br />
            <Field
              type="text"
              class="form-control"
              name="stoplossPrice"
              autocomplete="off"
              placeholder="Price"
              v-model="buySellObj.price"
              rules="required:price"
              :validateOnInput="true"
              v-on:keyup="checkMultiple(buySellObj.price, buySellObj.tick_size)"
              v-on:keypress="$helperService.allowDecimalValue($event)" />
            <ErrorMessage name="Price" class="text-white" />
            <p v-if="isTickSize" style="font-size: 13px; color: white">
              Price Should be in multiple of {{ buySellObj.tick_size }}
            </p>
          </div>
          <div class="col-sm-2 mt-1">
            <label
              style="
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: 14px !important;
              "
              class="col-form-label"
              >Client</label
            ><br />
            <Field
              type="text"
              name="user"
              v-model="buySellObj.user_id"
              rules="required:user"
              :validateOnInput="true">
              <Multiselect
                mode="single"
                trackBy="new_name"
                label="new_name"
                valueProp="id"
                class="register-select"
                placeholder="Client"
                v-model="buySellObj.user_id"
                :options="client_list"
                :can-clear="false"
                openDirection="top"
                searchable="true" />
              <ErrorMessage name="user" class="text-white" />
            </Field>
          </div>
          <div class="col-sm-2 mt-4">
            <button id="submitForm" class="btn btn-success m-1">
              <i class="fa fa-trade"></i>
              <span> Submit </span>
            </button>
            <button
              type="button"
              v-on:click="closeBuySellPopup()"
              class="btn btn-danger m-1">
              <i class="fa fa-trade"></i>
              <span> Cancel </span>
            </button>
          </div>
        </div>
      </Form>
    </div>

    <!-- ============================== -->
    <div style="width: 100%" v-show="is_show_order_book">
      <div
        class="card buy-section ng-scope table-responsive"
        style="
          margin-bottom: 15px;
          height: 350px !important;
          max-height: 250px !important;
          overflow-y: scroll;
          margin: 7px !important ;
        ">
        <div class="modal-header pt-0">
          <p
            style="
              color: black;
              text-align: justify;
              font-weight: bold;
              font-size: 12px;
              margin: 0px !important;
              margin-top: 7px !important;
            ">
            Order Book
          </p>
          <div class="col-lg-2">
            <Field
              type="text"
              class="form-control"
              name="ledger_balance"
              placeholder="SEARCH"
              v-model="order_search"
              v-on:keyup="getOrderList()" />
          </div>
          <button type="button" class="close" v-on:click="closeModal('ORDER')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="sticky-table-header">
          <table
            id="table"
            class="table table-sm text-center text-white table-bordered product-list table-hover"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar">
            <thead>
              <tr>
                <!-- <th class="head" id="id-name">Execute </th> -->
                <th class="head" id="id-name">O. Time</th>
                <th class="head" id="id-name">Segment</th>
                <th class="head" id="id-name">Client</th>
                <th class="head" id="id-name">Symbol</th>
                <th class="head" id="id-name">Type</th>
                <th class="head" id="id-name">Lot</th>
                <th class="head" id="id-name">Qty</th>
                <th class="head" id="id-name">Order Price</th>
                <th class="head" id="id-name">Status</th>
                <th class="head" id="id-name">User</th>
                <!-- <th class="head " id="id-name">IP </th> -->
                <!-- <th
                class="head sorting"
                id="id-name"
                v-on:click="sorting('id', 'id-name')"
              >
                Modify 
              </th>
              <th
                class="head sorting"
                id="id-name"
                v-on:click="sorting('id', 'id-name')"
              >
                Cancel 
              </th> -->
              </tr>
            </thead>
            <tbody v-show="!orderLoader">
              <tr v-for="(data, index) in orderList" :key="index">
                <!-- <td :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'">
              {{ data.trade_status == "EXECUTED" ? "Y" : "N" }}
            </td> -->

                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{
                    data.created_at
                      ? $helperService.getDate(data.created_at)
                      : ""
                  }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{
                    data.script
                      ? data.script.exchange == "COMEX"
                        ? "COMEX"
                        : "MCX"
                      : ""
                  }}
                </td>
                <td
                  style="text-align: left"
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{
                    data.user
                      ? $helperService.getUpperCase(
                          data.user?.name + "-" + data.user?.code
                        )
                      : ""
                  }}
                </td>
                <td
                  style="text-align: left"
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.trading_symbol ? data.trading_symbol : "" }}
                </td>
                <td
                  style="text-align: left"
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                  :style="
                    data.order_action == 'BUY' ? 'color: blue;' : 'color: red;'
                  ">
                  {{
                    data.order_type == "MARKET"
                      ? data.order_action
                      : data.order_type == "INTRADAY"
                      ? "Intraday Close"
                      : data.order_action + " " + data.order_type
                  }}
                </td>
                <td
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                  v-if="data.isEdit == false">
                  {{ data.lot?.toFixed(2) }}
                </td>
                <td
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                  v-if="data.isEdit == false">
                  {{ data.quantity }}
                </td>
                <!-- <td
                :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                v-if="data.isEdit == true"
              >
                <div style="display: block ruby">
                  <Field
                    type="text"
                    class="form-control"
                    name="Lot"
                    style="width: 100px; height: 25px !important"
                    :style="data.script.lot_size == 1 ? 'cursor: no-drop;' : ''"
                    v-model="data.lot"
                    :disabled="data.script.lot_size == 1"
                    v-on:keyup="
                      updateQuantity(data.lot, data.script.lot_size, index)
                    "
                    placeholder="Lot"
                    v-on:keypress="$helperService.allowDecimalValue($event)"
                  />
                </div>
              </td>
              <td
                :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                v-if="data.isEdit == false"
              >
                {{ data.quantity }}
              </td>
              <td
                :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                v-if="data.isEdit == true"
              >
                <div style="display: block ruby">
                  <Field
                    type="text"
                    class="form-control"
                    name="Qty"
                    style="width: 100px; height: 25px !important"
                    :style="data.script.lot_size != 1 ? 'cursor: no-drop;' : ''"
                    v-model="data.quantity"
                    placeholder="Qty"
                    :disabled="data.script.lot_size != 1"
                    v-on:keypress="$helperService.allowDecimalValue($event)"
                  />
                </div>
              </td> -->
                <td
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                  v-if="data.isEdit == false">
                  {{ data.price }}
                </td>
                <td
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                  v-if="data.isEdit == true">
                  <div style="display: block ruby">
                    <Field
                      type="text"
                      style="width: 100px; height: 25px !important"
                      class="form-control"
                      name="Price"
                      placeholder="Price"
                      v-model="data.price"
                      v-on:keypress="
                        $helperService.allowDecimalValue($event)
                      " />
                  </div>
                </td>

                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.trade_status }}
                </td>
                <td
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                  style="text-align: left">
                  {{
                    data.user
                      ? $helperService.getUpperCase(data.user?.name)
                      : ""
                  }}
                </td>
                <!-- <td :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'">
              {{ data.ip }}
            </td> -->
                <!-- <td
                :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
              >
                <i
                  v-if="data.isEdit == false && data.trade_status == 'PENDING'"
                  v-on:click="editRow(index)"
                  class="fa fa-pencil-square-o mr-2"
                  aria-hidden="true"
                  style="cursor: pointer; color: rgb(29, 68, 91)"
                ></i>
                <i
                  v-if="data.isEdit == true"
                  v-on:click="updateRow(index)"
                  class="fa fa-check mr-2"
                  aria-hidden="true"
                  style="cursor: pointer; color: green"
                ></i>
                <i
                  v-if="data.isEdit == true"
                  v-on:click="closeIsEdit()"
                  class="fa fa-times mr-2"
                  aria-hidden="true"
                  style="cursor: pointer; color: red"
                ></i>
              </td> -->
                <!-- <td
                :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
              >
                <i
                  v-if="data.trade_status == 'PENDING'"
                  v-on:click="showDeletePopup(data)"
                  class="fa fa-trash-o"
                  aria-hidden="true"
                  style="cursor: pointer; color: red"
                ></i>
              </td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <ErrorComponent @retry="getOrderList(0)" ref="orderListRef" />
      </div>
    </div>
    <!-- Table -2 -->
    <div style="width: 100%" v-show="is_show_trade_book">
      <div
        class="card buy-section ng-scope table-responsive col-lg-12"
        style="
          margin-bottom: 15px;
          height: 350px !important;
          max-height: 250px !important;
          overflow-y: scroll;
          margin: 7px !important;
        ">
        <div class="modal-header pt-0">
          <p
            style="
              color: black;
              text-align: justify;
              font-weight: bold;
              font-size: 12px;
              margin: 0px !important;
              margin-top: 7px !important;
            ">
            Trade Book
          </p>
          <div class="col-lg-2">
            <Field
              type="text"
              class="form-control"
              name="ledger_balance"
              placeholder="SEARCH"
              v-model="trade_search"
              v-on:keyup="getTradeList()" />
          </div>
          <button type="button" class="close" v-on:click="closeModal('TRADE')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="sticky-table-header">
          <table
            id="table"
            class="table table-sm text-center text-white table-bordered product-list table-hover"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar">
            <thead>
              <tr>
                <th class="head">Sr No.</th>
                <th class="head">Trade No</th>
                <th class="head">D</th>
                <th class="head">O/T</th>
                <th class="head">Trade Date</th>
                <th class="head">Client</th>
                <th class="head">Athority</th>
                <th class="head">Script</th>
                <th class="head">Type</th>
                <th class="head">Lot</th>
                <th class="head">Qty</th>

                <th class="head">Rate</th>
                <th class="head">Net Rate</th>
                <th class="head">User</th>
                <th class="head">IP</th>
                <th class="head">T-T</th>
              </tr>
            </thead>
            <tbody v-show="!tradeLoader">
              <tr v-for="(data, index) in tradeList" :key="index">
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ index + 1 }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.id }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  <i
                    v-if="data.device == 'WEB'"
                    class="fa fa-globe"
                    aria-hidden="true"></i>
                  <i
                    v-if="data.device == 'MOBILE'"
                    class="fa fa-mobile"
                    aria-hidden="true"></i>
                  <i
                    class="fa fa-user"
                    v-if="data.device == 'ADMIN'"
                    aria-hidden="true"></i>
                  <i
                    class="fa fa-user"
                    v-if="!data.device"
                    aria-hidden="true"></i>
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.order_type }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{
                    data.created_at
                      ? $helperService.getDate(data.created_at)
                      : ""
                  }}
                </td>
                <td
                  style="text-align: left"
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{
                    data.user
                      ? $helperService.getUpperCase(
                          data.user?.name + "-" + data.user?.code
                        )
                      : ""
                  }}
                </td>
                <td
                  style="text-align: left"
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{
                    data.athority
                      ? $helperService.getUpperCase(
                          data.athority?.code + "-" + data.user?.code
                        )
                      : ""
                  }}
                </td>
                <td
                  style="text-align: left"
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.trading_symbol ? data.trading_symbol : "" }}
                </td>
                <td
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                  :style="
                    data.order_action == 'BUY' ? 'color: blue;' : 'color: red;'
                  ">
                  {{
                    data.order_type == "MARKET"
                      ? data.order_action
                      : data.order_type == "INTRADAY"
                      ? "Intraday Close"
                      : data.order_action + " " + data.order_type
                  }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.lot?.toFixed(2) }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.quantity }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.price }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.net_price?.toFixed(2) }}
                </td>
                <td
                  style="text-align: left"
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{
                    data.user
                      ? $helperService.getUpperCase(data.user?.name)
                      : ""
                  }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.ip }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{
                    data.trade_date
                      ? $helperService.getDateTime(data.trade_date)
                      : ""
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ErrorComponent @retry="getTradeList(0)" ref="tradeListRef" />
      </div>
    </div>
    <!-- Table -3  -->
    <div
      style="width: 100% !important"
      class="col-12"
      v-show="is_show_position_report">
      <div
        class="card buy-section ng-scope table-responsive col-lg-12 mb-3"
        style="
          margin: -8px !important;
          height: 400px !important;
          max-height: 250px !important;
          overflow-y: scroll;
        ">
        <div class="modal-header pt-0">
          <p
            style="
              color: black;
              text-align: justify;
              font-weight: bold;
              font-size: 12px;
              margin: 0px !important;
              margin-top: 7px !important;
            ">
            Position Book
          </p>
          <div class="col-lg-2">
            <Field
              type="text"
              class="form-control"
              name="ledger_balance"
              placeholder="SEARCH"
              v-model="position_search"
              v-on:keyup="getPositionReportList()" />
          </div>
          <div class="col-8 row" style="justify-content: end">
            <div class="col-2" style="display: contents">
              <button
                id="btn-dd"
                type="button"
                style="background-color: orange !important"
                v-on:click="rollOverPosition()"
                class="btn btn-primary mb-2">
                Rollover
              </button>
            </div>
            <div class="col-2">
              <button
                id="btn-dd"
                type="button"
                v-on:click="closePosition()"
                class="btn btn-primary mb-2">
                Close Position
              </button>
            </div>
          </div>
          <button
            type="button"
            class="close"
            v-on:click="closeModal('POSITION')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="sticky-table-header">
          <table
            id="table"
            class="table table-sm text-center text-white table-bordered product-list table-hover"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar">
            <thead>
              <tr>
                <th class="head ml-2">
                  <div class="switchToggleRead">
                    <input
                      type="checkbox"
                      v-model="isChecked"
                      :true-value="true"
                      :false-value="false"
                      v-on:change="checkall(isChecked)" />
                  </div>
                </th>
                <th class="head">Segment</th>
                <th class="head">Client</th>
                <th class="head">Symbol</th>
                <th class="head">Total Buy Qty</th>

                <th class="head">Buy Avg. Price</th>
                <th class="head">Total Sell Qty</th>

                <th class="head">Sell Avg. Price</th>
                <th class="head">Net Lot</th>
                <th class="head">Net Qty</th>
                <th class="head">B.E. Avg.</th>
                <th class="head">LTP</th>
                <th class="head">MTM</th>
              </tr>
            </thead>
            <tbody v-show="!positionLoader">
              <tr v-for="(data, index) in positionReportList" :key="index">
                <td style="font-size: 11px" class="pl-1 pt-1 pr-1 p-0">
                  <!-- v-if="data.net_qty != 0" -->
                  <div class="switchToggleRead" v-if="data.net_qty != 0">
                    <input
                      :id="'index_' + data.id"
                      type="checkbox"
                      v-model="data.isChecked"
                      v-on:change="checkHandlling(data.isChecked)"
                      :true-value="true"
                      :false-value="false" />
                  </div>
                </td>
                <td
                  :class="
                    Number(data.total_buy_quantity) >
                    Number(data.total_sell_quantity)
                      ? 'body-buy'
                      : 'body-sell'
                  ">
                  {{
                    data.script
                      ? data.script.exchange == "COMEX"
                        ? "COMEX"
                        : "MCX"
                      : ""
                  }}
                </td>
                <td
                  :class="
                    Number(data.total_buy_quantity) >
                    Number(data.total_sell_quantity)
                      ? 'body-buy'
                      : 'body-sell'
                  ">
                  {{
                    data.user
                      ? $helperService.getUpperCase(
                          data.user?.name + "-" + data.user?.code
                        )
                      : ""
                  }}
                </td>
                <td
                  :class="
                    Number(data.total_buy_quantity) >
                    Number(data.total_sell_quantity)
                      ? 'body-buy'
                      : 'body-sell'
                  ">
                  {{ data.trading_symbol ? data.trading_symbol : "" }}
                </td>
                <td
                  :class="
                    Number(data.total_buy_quantity) >
                    Number(data.total_sell_quantity)
                      ? 'body-buy'
                      : 'body-sell'
                  ">
                  {{
                    Number(data.total_buy_quantity)
                      ? $helperService.getFormattedPrice(
                          data.total_buy_quantity
                        )
                      : 0
                  }}
                </td>
                <td
                  :class="
                    Number(data.total_buy_quantity) >
                    Number(data.total_sell_quantity)
                      ? 'body-buy'
                      : 'body-sell'
                  ">
                  {{
                    Number(data.avg_buy_price)
                      ? $helperService.getFormattedPrice(data.avg_buy_price)
                      : 0
                  }}
                </td>
                <td
                  :class="
                    Number(data.total_buy_quantity) >
                    Number(data.total_sell_quantity)
                      ? 'body-buy'
                      : 'body-sell'
                  ">
                  {{
                    Number(data.total_sell_quantity)
                      ? $helperService.getFormattedPrice(
                          data.total_sell_quantity
                        )
                      : 0
                  }}
                </td>
                <td
                  :class="
                    Number(data.total_buy_quantity) >
                    Number(data.total_sell_quantity)
                      ? 'body-buy'
                      : 'body-sell'
                  ">
                  {{
                    Number(data.avg_sell_price)
                      ? $helperService.getFormattedPrice(data.avg_sell_price)
                      : 0
                  }}
                </td>
                <td
                  :class="
                    Number(data.total_buy_quantity) >
                    Number(data.total_sell_quantity)
                      ? 'body-buy'
                      : 'body-sell'
                  ">
                  {{ data.net_lot ? data.net_lot?.toFixed(2) : 0 }}
                </td>

                <td
                  :class="
                    Number(data.total_buy_quantity) >
                    Number(data.total_sell_quantity)
                      ? 'body-buy'
                      : 'body-sell'
                  ">
                  {{ data.net_qty }}
                </td>
                <td
                  :class="
                    Number(data.total_buy_quantity) >
                    Number(data.total_sell_quantity)
                      ? 'body-buy'
                      : 'body-sell'
                  ">
                  <!-- {{
                      data.net_qty != 0
                        ? (data.total_sell_quantity * data.avg_sell_price -
                            data.total_buy_quantity * data.avg_buy_price) /
                          data.net_qty
                        : "-"
                    }} -->
                  {{ Number(data.bea) ? data.bea?.toFixed(2) : 0 }}
                </td>

                <td
                  style="padding: 0px !important; font-size: 13px !important"
                  v-if="data.ltp">
                  <div
                    style="padding: 0.3rem"
                    :style="'background-color:' + data.ltpc">
                    {{ data.ltp?.toFixed(2) }}
                  </div>
                </td>
                <td
                  v-if="!data.ltp"
                  :class="
                    Number(data.total_buy_quantity) >
                    Number(data.total_sell_quantity)
                      ? 'body-buy'
                      : 'body-sell'
                  ">
                  <!-- {{ data.m2m?.toFixed(2) }} -->
                </td>
                <td
                  style="font-weight: bold; font-size: 13px !important"
                  :class="
                    Number(data.m2m?.toFixed(2)) > 0 ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.m2m?.toFixed(2) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ErrorComponent
          @retry="getPositionReportList(0)"
          ref="positionReportRef" />
      </div>
    </div>
    <ErrorComponent @retry="getCOMEXWishlist()" ref="errorComponent" />
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/ErrorComponent";
import webstomp from "webstomp-client";
import swal from "sweetalert2";
import moment from "moment";

export default {
  created() {
    let that = this;
    document.addEventListener("keyup", function (evt) {
      console.log("object", evt.keyCode);
      // up
      if (evt.keyCode === 38) {
      
        if (
          that.comex_wishlist.length > 0 &&
          that.is_show_buy_sell_section == false
        ) {
          for (var j in that.comex_wishlist) {
            if (that.comex_wishlist[j].is_highlight) {
              if (j > 0) {
                that.comex_wishlist[j].is_highlight = false;
                that.comex_wishlist[Number(j) - Number(1)].is_highlight = true;
                that.setBuySellDetailbyUpDown(
                  that.comex_wishlist[Number(j) - Number(1)]
                );
              }
              break;
            }
          }
        }
      }
      // down
      if (evt.keyCode === 40) {
        if (
          that.comex_wishlist.length > 0 &&
          that.is_show_buy_sell_section == false
        ) {
          for (var m in that.comex_wishlist) {
            if (that.comex_wishlist[m].is_highlight) {
              if (that.comex_wishlist[Number(m) + Number(1)]) {
                that.comex_wishlist[m].is_highlight = false;
                that.comex_wishlist[Number(m) + Number(1)].is_highlight = true;
                that.setBuySellDetailbyUpDown(
                  that.comex_wishlist[Number(m) + Number(1)]
                );
              }
              break;
            }
          }
        }
      }
      if (evt.keyCode === 27) {
        that.keyword = "";
        that.is_show_trade_book = false;
        that.is_show_order_book = false;
        that.is_show_position_report = false;
        that.closeBuySellPopup();
      }
      if (evt.keyCode === 37) {
        if (that.selected_obj) {
          that.buySellObj.order_action = "SELL";
          that.showBuySellPopup(that.selected_obj);
        }
        that.is_show_order_book = false;
        that.is_show_trade_book = false;
        that.is_show_position_report = false;
      }
      if (evt.keyCode === 109) {
        if (that.selected_obj) {
          that.buySellObj.order_action = "SELL";
          that.showBuySellPopup(that.selected_obj);
        }
        that.is_show_order_book = false;
        that.is_show_trade_book = false;
        that.is_show_position_report = false;
      }
      if (evt.keyCode === 39) {
        if (that.selected_obj) {
          that.buySellObj.order_action = "BUY";
          that.showBuySellPopup(that.selected_obj);
        }
        that.is_show_order_book = false;
        that.is_show_trade_book = false;
        that.is_show_position_report = false;
      }
      if (evt.keyCode === 107) {
        if (that.selected_obj) {
          that.buySellObj.order_action = "BUY";
          that.showBuySellPopup(that.selected_obj);
        }
        that.is_show_order_book = false;
        that.is_show_trade_book = false;
        that.is_show_position_report = false;
      }
    });
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    ErrorComponent,
  },
  name: "Dashboard",
  data() {
    return {
      isTickSize: false,
      is_merge: localStorage.getItem("is_merge")
        ? JSON.parse(localStorage.getItem("is_merge"))
        : true,
      role: localStorage.getItem("role"),
      loader: true,
      orderLoader: true,
      tradeLoader: true,
      positionLoader: true,
      isShowNse: true,
      isShowMcx: true,
      isShowMerge: true,
      keyword: "",
      sensexPoints: 0,
      sensexPosition: 0,
      niftyPoints: 0,
      niftyPosition: 0,
      reject_reason: "",
      order_search: "",
      trade_search: "",
      position_search: "",
      selected_obj: null,
      lot_size: "",
      buySellObj: {
        order_action: "BUY",
        order_type: "MARKET",
      },
      is_show_buy_sell_section: false,
      is_show_order_book: false,
      is_show_trade_book: false,
      is_show_position_report: false,
      exchanges: [
        {
          name: "COMEX",
          value: "COMEX",
        }
      ],
      message: "",
      detail: {
        exchange: "",
        expire_token: "",
        instrument_token: "",
      },
      scriptList: [],
      expireList: [],
      comex_wishlist: [],
      client_list: [],
      orderList: [],
      tradeList: [],
      positionReportList: [],
      client: undefined,
      mcx_last_update: "",
      nse_last_update: "",
    };
  },
  mounted() {
   this.getScriptName("COMEX");
    this.getCOMEXWishlist();
    this.getClientList();
    this.getDetails();
    this.connect();
    var $this = this;
    setInterval(() => {
      $this.checkConnection();
    }, 5000);
  },
  methods: {
    getDetails() {
      this.$api
        .getAPI({
          _action: "/settings",
        })
        .then((res) => {
          this.message = res.header_message;
        })
        .catch(() => {});
    },
   
    checkMultiple(price, tick_size) {
      if (price && tick_size) {
        var n = price;
        n = (n - Math.floor(n)).toFixed(2);
        var tick = tick_size;
        var is_check = (n / tick).toFixed(2) % 1 == 0;
        if (is_check) {
          this.isTickSize = false;
        } else {
          this.isTickSize = true;
        }
        console.log("is_check", is_check);
      }
    },
    checkall(isChecked) {
      for (var k in this.positionReportList) {
        if (isChecked) {
          if (this.positionReportList[k].net_qty != 0) {
            this.positionReportList[k].isChecked = true;
          }
        } else if (!isChecked) {
          this.positionReportList[k].isChecked = false;
        }
      }
    },
    checkHandlling(isChecked) {
      if (!isChecked) {
        this.isChecked = false;
      } else if (isChecked) {
        var count = 0;
        for (var k in this.positionReportList) {
          if (this.positionReportList[k].isChecked == false) {
            count = count + 1;
          }
        }
      }

      if (count == 0) {
        this.isChecked = true;
      }
    },
    closePosition() {
      this.$refs.errorComponent.updateFormLoader(true);
      var obj = {};
      var arr = [];
      obj.device = "WEB";
      for (var k in this.positionReportList) {
        if (this.positionReportList[k].isChecked == true) {
          arr.push(this.positionReportList[k]);
        }
      }
      obj.close_position_array = arr;
      if (arr.length == 0) {
        this.$notify({
          type: "error",
          text: "Please select Postions First",
        });
        this.$refs.errorComponent.updateFormLoader(false);
        return;
      }
      this.$api
        .postAPI({
          _action: "/close-position",
          _body: obj,
        })
        .then((res) => {
          this.$refs.errorComponent.updateFormLoader(false);
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });
          this.getPositionReportList();
        })
        .catch(() => {
          this.$refs.errorComponent.updateFormLoader(false);
        });
    },
    rollOverPosition() {
      this.$refs.errorComponent.updateFormLoader(true);
      var obj = {};
      var arr = [];
      obj.device = "WEB";
      for (var k in this.positionReportList) {
        if (this.positionReportList[k].isChecked == true) {
          arr.push(this.positionReportList[k]);
        }
      }
      obj.close_position_array = arr;
      if (arr.length == 0) {
        this.$notify({
          type: "error",
          text: "Please select Postions First",
        });
        this.$refs.errorComponent.updateFormLoader(false);
        return;
      }
      this.$api
        .postAPI({
          _action: "/roll-over",
          _body: obj,
        })
        .then((res) => {
          this.$refs.errorComponent.updateFormLoader(false);
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });
          this.getPositionReportList();
        })
        .catch(() => {
          this.$refs.errorComponent.updateFormLoader(false);
        });
    },
    closeModal(type) {
      if (type == "TRADE") {
        this.is_show_trade_book = !this.is_show_trade_book;
      }
      if (type == "ORDER") {
        this.is_show_order_book = !this.is_show_order_book;
      }
      if (type == "POSITION") {
        this.is_show_position_report = !this.is_show_position_report;
      }
    },
    showOrderBookTable() {
      this.is_show_trade_book = false;
      this.is_show_position_report = false;
      this.is_show_order_book = !this.is_show_order_book;
      this.getOrderList();
    },
    showTradeBookTable() {
      this.is_show_position_report = false;
      this.is_show_order_book = false;
      this.is_show_trade_book = !this.is_show_trade_book;
      this.getTradeList();
    },
    showPositionReportTable() {
      this.is_show_trade_book = false;
      this.is_show_order_book = false;
      this.is_show_position_report = !this.is_show_position_report;
      this.getPositionReportList();
    },
    getOrderList() {
      if (this.$refs.orderListRef) {
        this.$refs.orderListRef.updateListLoader(true);
      }
      var searchObj = {};
      if (this.order_search) {
        searchObj.keyword = this.order_search;
      }
      searchObj.day = "TODAY";
      searchObj.trade_status = "PENDING";
      searchObj.order_type = "LIMIT,STOPLOSS,INTRADAY";
      this.orderList = [];
      this.$api
        .getAPI({
          _action: "/trade-list",
          _body: searchObj,
        })
        .then((res) => {
          this.orderLoader = false;
          this.orderList = res;
          res.list = this.orderList;
          for (var i in this.orderList) {
            this.orderList[i].isEdit = false;
          }
          if (this.$refs.orderListRef) {
            this.$refs.orderListRef.setData(res, "No Data available");
            this.$refs.orderListRef.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.orderListRef) {
            this.$refs.orderListRef.setData(0, undefined, "");
            this.$refs.orderListRef.updateFormLoader(false);
          }
        });
    },
    getTradeList() {
      if (this.$refs.tradeListRef) {
        this.$refs.tradeListRef.updateListLoader(true);
      }
      var searchObj = {};
      if (this.trade_search) {
        searchObj.keyword = this.trade_search;
      }
      searchObj.day = "TODAY";
      searchObj.trade_status = "EXECUTED";
      searchObj.sort_by = "trade_date";
      searchObj.order_by = "desc";
      this.tradeList = [];
      this.$api
        .getAPI({
          _action: "/trade-list",
          _body: searchObj,
        })
        .then((res) => {
          this.tradeLoader = false;
          this.tradeList = res;
          res.list = this.tradeList;
          if (this.$refs.tradeListRef) {
            this.$refs.tradeListRef.setData(res, "No Data available");
            this.$refs.tradeListRef.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.tradeListRef) {
            this.$refs.tradeListRef.setData(0, undefined, "");
            this.$refs.tradeListRef.updateFormLoader(false);
          }
        });
    },
    getPositionReportList() {
      if (this.$refs.positionReportRef) {
        this.$refs.positionReportRef.updateListLoader(true);
      }
      this.positionReportList = [];
      var searchObj = {};
      if (this.position_search) {
        searchObj.keyword = this.position_search;
      }
      this.$api
        .getAPI({
          _action: "/position-report-list",
          _body: searchObj,
        })
        .then((res) => {
          this.positionLoader = false;
          this.positionReportList = res;
          res.list = this.positionReportList;
          for (var k in this.positionReportList) {
            this.positionReportList[k].isChecked = false;

            if (this.positionReportList[k].net_qty != 0) {
              var avg =
                (this.positionReportList[k].total_sell_quantity *
                  this.positionReportList[k].avg_sell_price -
                  this.positionReportList[k].total_buy_quantity *
                    this.positionReportList[k].avg_buy_price) /
                this.positionReportList[k].net_qty;
              this.positionReportList[k].bea = Math.abs(avg);
            }
          }
          if (this.$refs.positionReportRef) {
            this.$refs.positionReportRef.setData(res, "No Data available");
            // this.$refs.positionReportRef.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.positionReportRef) {
            this.$refs.positionReportRef.setData(0, undefined, "");
            this.$refs.positionReportRef.updateListLoader(false);
          }
        });
    },
    showNseTable() {
      this.isShowNse = !this.isShowNse;
    },
    showMcxTable() {
      this.isShowMcx = !this.isShowMcx;
    },
    showMergeTable() {
      this.isShowMerge = !this.isShowMerge;
    },
    checkConnection() {
      if (!this.client || !this.client.connected) {
        // console.log("try to reconnect connected");
        this.connect();
      } else {
        // console.log("already connected");
      }
    },
    connect() {
      var $this = this;
      this.client = webstomp.client(this.$api.url, { debug: false });
      this.client.connect(this.$api.username, this.$api.password, () => {
        this.client.subscribe("/exchange/comex-data", (message) => {
          $this.showData(JSON.parse(message.body));
        });
      });
    },
    showData(data) {
      for (var i in data) {
        // console.log(data[i])
        // this.sensexPoints = -345;
        // this.niftyPoints = -534;
        // this.sensexPosition = 43245;
        // this.niftyPosition = 34534;
        if (data[i].it == 265) {
          this.sensexPosition = data[i].ltp;
          this.sensexPoints = data[i].n;
        }
        if (data[i].it == 256265) {
          this.niftyPosition = data[i].ltp;
          this.niftyPoints = data[i].n;
        }
        for (var o in this.positionReportList) {
          if (this.positionReportList[o].instrument_token == data[i].it) {
            this.positionReportList[o].ltp = data[i].ltp;
            this.positionReportList[o].ltpc = data[i].ltpc;
            if (this.positionReportList[o].net_qty != 0) {
              this.positionReportList[o].m2m =
                this.$helperService.getFormattedPrice(
                  this.positionReportList[o].ltp -
                    this.positionReportList[o].bea
                ) * this.positionReportList[o].net_qty;
            } else {
              this.positionReportList[o].m2m =
                this.positionReportList[o].total_sell_quantity *
                  this.positionReportList[o].avg_sell_price -
                this.positionReportList[o].total_buy_quantity *
                  this.positionReportList[o].avg_buy_price;
            }
          }
        }
        this.$refs.positionReportRef?.updateListLoader(false);
        if (data[i].it == this.buySellObj.instrument_token) {
          this.buySellObj.a = data[i].a;
          this.buySellObj.ba = data[i].ba;
          this.buySellObj.ac = data[i].ac;
          this.buySellObj.b = data[i].b;
          this.buySellObj.bc = data[i].bc;
          this.buySellObj.a = data[i].a;
          this.buySellObj.ch = data[i].ch;
          this.buySellObj.h = data[i].h;
          this.buySellObj.l = data[i].l;
          this.buySellObj.o = data[i].o;
          this.buySellObj.c = data[i].c;
          this.buySellObj.ltp = data[i].ltp;
          this.buySellObj.ltpc = data[i].ltpc;
          this.buySellObj.n = data[i].n;
          this.buySellObj.ll = data[i].ll;
          this.buySellObj.ul = data[i].ul;
          this.buySellObj.lr = data[i].lr;
        }

        for (var k in this.comex_wishlist) {
          if (this.comex_wishlist[k].instrument_token == data[i].it) {
            this.nse_last_update = new Date().getTime();
            this.comex_wishlist[k].a = data[i].a;
            this.comex_wishlist[k].ba = data[i].ba;
            this.comex_wishlist[k].b = data[i].b;
            this.comex_wishlist[k].ac = data[i].ac;
            this.comex_wishlist[k].bc = data[i].bc;
            this.comex_wishlist[k].ltpc = data[i].ltpc;
            this.comex_wishlist[k].c = data[i].c;
            this.comex_wishlist[k].ltp = data[i].ltp;
            this.comex_wishlist[k].it = data[i].it;
            this.comex_wishlist[k].ch = data[i].ch;
            this.comex_wishlist[k].h = data[i].h;
            this.comex_wishlist[k].l = data[i].l;
            this.comex_wishlist[k].o = data[i].o;
            this.comex_wishlist[k].n = data[i].n;
            this.comex_wishlist[k].ll = data[i].ll;
            this.comex_wishlist[k].ul = data[i].ul;
            this.comex_wishlist[k].lr = data[i].lr;
          }
        }
      }
    },
    sorting(sortBy, id, type) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(obj.order_by, obj.sort_by, type);
    },
    getList(orderBy, sortBy, type) {
      if (type == "MCX") {
        this.getMCXWishlist(orderBy, sortBy);
      } else if (type == "COMEX") {
        this.getCOMEXWishlist(orderBy, sortBy);
      } else if (type == "MERGE") {
        this.getMergeWishlist(orderBy, sortBy);
      } else {
        this.getMCXWishlist();
        this.getCOMEXWishlist();
        this.getMergeWishlist();
      }
    },
    setBuySellDetail(data, index, type) {
      this.is_show_buy_sell_section = false;
      this.selected_obj = data;
      this.buySellObj.instrument_token = data.instrument_token;
      this.buySellObj.tick_size = data.tick_size;
      this.buySellObj.exchange = data.exchange;
      this.buySellObj.expiry = data.expiry;
      this.buySellObj.name_new =
        data.name + this.$helperService.getCustomDate(data.expiry);
      this.buySellObj.trading_symbol =
        data.name + " " + this.$helperService.getCustomDate(data.expiry);
      if (this.mcx_wishlist.length > 0) {
        for (var i in this.mcx_wishlist) {
          if (index == i && type == "MCX") {
            this.mcx_wishlist[i].is_highlight = true;
          } else {
            this.mcx_wishlist[i].is_highlight = false;
          }
        }
      }
      if (this.comex_wishlist.length > 0) {
        for (var j in this.comex_wishlist) {
          if (index == j && type == "COMEX") {
            this.comex_wishlist[j].is_highlight = true;
          } else {
            this.comex_wishlist[j].is_highlight = false;
          }
        }
      }
      if (this.merge_wishlist.length > 0) {
        for (var v in this.merge_wishlist) {
          if (index == v && type == "MERGE") {
            this.merge_wishlist[v].is_highlight = true;
          } else {
            this.merge_wishlist[v].is_highlight = false;
          }
        }
      }
    },
    setBuySellDetailDoubleClick(e, data, index, type) {
      // if (e.type == "dblclick")
      this.is_show_buy_sell_section = true;
      this.selected_obj = data;
      this.buySellObj.instrument_token = data.instrument_token;
      this.buySellObj.tick_size = data.tick_size;
      this.buySellObj.exchange = data.exchange;
      this.buySellObj.expiry = data.expiry;
      this.buySellObj.name_new =
        data.name + this.$helperService.getCustomDate(data.expiry);
      this.buySellObj.name = data.trading_symbol;
      this.buySellObj.trading_symbol =
        data.name + " " + this.$helperService.getCustomDate(data.expiry);
      if (this.mcx_wishlist.length > 0) {
        for (var i in this.mcx_wishlist) {
          if (index == i && type == "MCX") {
            this.mcx_wishlist[i].is_highlight = true;
          } else {
            this.mcx_wishlist[i].is_highlight = false;
          }
        }
      }
      if (this.comex_wishlist.length > 0) {
        for (var j in this.comex_wishlist) {
          if (index == j && type == "COMEX") {
            this.comex_wishlist[j].is_highlight = true;
          } else {
            this.comex_wishlist[j].is_highlight = false;
          }
        }
      }

      if (this.merge_wishlist.length > 0) {
        for (var t in this.comex_wishlist) {
          if (index == t && type == "MERGE") {
            this.merge_wishlist[t].is_highlight = true;
          } else {
            this.merge_wishlist[t].is_highlight = false;
          }
        }
      }

      this.showBuySellPopup(this.selected_obj);
      // }
    },
    updateQuantity(lot) {
      this.buySellObj.quantity = lot * this.lot_size;
    },
    updateLot() {
      this.buySellObj.lot_size = this.$helperService.getFormattedPrice(
        this.buySellObj.quantity / this.lot_size
      );
    },
    showBuySellPopup(data) {
      if (
        this.role == "USER" &&
        this.client_list.length > 0 &&
        this.client_list[0]
      ) {
        this.buySellObj.user_id = this.client_list[0].id;
      }

      this.is_show_buy_sell_section = true;
      this.buySellObj.name = data.trading_symbol;
      this.buySellObj.a = data.a;
      this.buySellObj.b = data.b;
      this.lot_size = data.lot_size;
      if (data.lot_size == 1) {
        this.buySellObj.lot_size = data.lot_size;
        this.buySellObj.quantity = data.lot_size;
      }
      if (data.lot_size > 1) {
        this.buySellObj.lot_size = 1;
        this.buySellObj.quantity = data.lot_size;
      }

      setTimeout(() => {
        document.getElementById("lotInput")?.focus();
      }, 100);
    },

    setBuySellDetailbyUpDown(data) {
      this.selected_obj = { ...data };
      this.buySellObj.instrument_token = data.instrument_token;
      this.buySellObj.exchange = data.exchange;
      this.buySellObj.expiry = data.expiry;
      this.buySellObj.name_new =
        data.name + " " + this.$helperService.getCustomDate(data.expiry);
      this.buySellObj.trading_symbol =
        data.name + " " + this.$helperService.getCustomDate(data.expiry);
    },

    submit() {
      if (this.isTickSize) {
        return;
      }

      swal
        .fire({
          title: "Are you sure?",
          text: "You sure want to place order.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Place Order",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            if (
              Number(this.buySellObj.price) < Number(this.buySellObj.ll) &&
              this.buySellObj.order_type != "MARKET" &&
              this.buySellObj.order_type != "INTRADAY"
            ) {
              swal.fire({
                title: "Error",
                text:
                  "price should be greater then lower limit " +
                  this.buySellObj.ll +
                  ".",
                icon: "error",
                showCancelButton: true,
                showConfirmButton: false,
              });
              this.reject_reason =
                "price should be greater then lower limit " +
                this.buySellObj.ll +
                ".";
            }
            if (
              Number(this.buySellObj.price) > Number(this.buySellObj.ul) &&
              this.buySellObj.order_type != "MARKET" &&
              this.buySellObj.order_type != "INTRADAY"
            ) {
              swal.fire({
                title: "Error",
                text:
                  "price should be less then upper limit " +
                  this.buySellObj.ul +
                  ".",
                icon: "error",
                showCancelButton: true,
                showConfirmButton: false,
              });
              this.reject_reason =
                "price should be less then upper limit " +
                this.buySellObj.ul +
                ".";
            }

            if (
              this.buySellObj.order_action == "BUY" &&
              this.buySellObj.order_type == "STOPLOSS"
            ) {
              if (
                this.buySellObj.price <= this.buySellObj.ltp &&
                this.buySellObj.price <= this.buySellObj.a
              ) {
                swal.fire({
                  title: "Error",
                  text: "Stoploss price should be greater then ltp price.",
                  icon: "error",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
                this.reject_reason =
                  "Stoploss price should be greater then ltp price.";
              }
            }

            if (
              this.buySellObj.order_action == "SELL" &&
              this.buySellObj.order_type == "STOPLOSS"
            ) {
              if (
                this.buySellObj.price >= this.buySellObj.ltp &&
                this.buySellObj.price >= this.buySellObj.b
              ) {
                swal.fire({
                  title: "Error",
                  text: "Stoploss price should be less then ltp price.",
                  icon: "error",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
                this.reject_reason =
                  "Stoploss price should be less then ltp price.";
              }
            }

            if (this.buySellObj.order_type == "LIMIT") {
              if (
                this.buySellObj.price >= this.buySellObj.b &&
                this.buySellObj.price <= this.buySellObj.a
              ) {
                swal.fire({
                  title: "Error",
                  text: "Limit price should not be in between bid price and ask price.",
                  icon: "error",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
                this.reject_reason =
                  "Limit price should not be in between bid price and ask price.";
              }
            }
            if (
              this.buySellObj.order_type == "LIMIT" &&
              this.buySellObj.order_action == "BUY"
            ) {
              if (this.buySellObj.price > this.buySellObj.ltp) {
                swal.fire({
                  title: "Error",
                  text: "Limit price should be Less then LTP.",
                  icon: "error",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
                this.reject_reason = "Limit price should be Less then LTP.";
              }
            }
            if (
              this.buySellObj.order_type == "LIMIT" &&
              this.buySellObj.order_action == "SELL"
            ) {
              if (this.buySellObj.price < this.buySellObj.ltp) {
                swal.fire({
                  title: "Error",
                  text: "Limit price should be greater then LTP.",
                  icon: "error",
                  showCancelButton: true,
                  showConfirmButton: false,
                });
                this.reject_reason = "Limit price should be greater then LTP.";
              }
            }
            this.buySellObj.device = "WEB";
            if (
              this.buySellObj.order_action == "BUY" &&
              this.buySellObj.order_type == "MARKET"
            ) {
              this.buySellObj.price = this.buySellObj.a;
            } else if (
              this.buySellObj.order_action == "SELL" &&
              this.buySellObj.order_type == "MARKET"
            ) {
              this.buySellObj.price = this.buySellObj.b;
            }

            if (this.reject_reason) {
              this.buySellObj.reject_reason = this.reject_reason;
            }
            if (this.buySellObj.price) {
              this.buySellObj.price = Number(this.buySellObj.price);
            }
            this.$api
              .postAPI({
                _action: "/add-comex-trade",
                _body: this.buySellObj,
                _buttonId: "submitForm",
              })
              .then((res) => {
                // this.is_show_buy_sell_section = false;

                if (!this.reject_reason) {
                  if (this.buySellObj.order_type == "MARKET") {
                    for (var i in this.client_list) {
                      if (this.client_list[i].id == this.buySellObj.user_id) {
                        var code = this.client_list[i].code;
                        var name = this.client_list[i].name;
                      }
                    }
                    var text =
                      "Trade has been successfully executed. " +
                      this.buySellObj.name_new +
                      "   " +
                      this.$helperService.getCustomDateYear(
                        this.buySellObj.expiry
                      ) +
                      "   " +
                      this.buySellObj.order_action +
                      " " +
                      this.buySellObj.quantity +
                      "@" +
                      (res.price
                        ? Number(res.price).toFixed(2)
                        : Number(this.buySellObj.price).toFixed(2)) +
                      "    For " +
                      this.$helperService.getUpperCase(name) +
                      "(" +
                      code +
                      ")";
                    if (this.buySellObj.order_action == "SELL") {
                      this.$notify({
                        title: "SELL",
                        type: "error",
                        text: text,
                      });
                    }
                    if (this.buySellObj.order_action == "BUY") {
                      this.$notify({
                        title: "BUY",
                        type: "success",
                        text: text,
                      });
                    }
                  } else {
                    for (var j in this.client_list) {
                      if (this.client_list[j].id == this.buySellObj.user_id) {
                        var code_new = this.client_list[j].code;
                        var name_new = this.client_list[j].name;

                        this.client_list[j].name +
                          this.$helperService.getCustomDate(
                            this.client_list[j].expiry
                          );
                      }
                    }
                    if (this.buySellObj.order_type != "INTRADAY") {
                      var text_new =
                        "Order Placed Successfully " +
                        this.buySellObj.name_new +
                        "   " +
                        this.$helperService.getCustomDateYear(
                          this.buySellObj.expiry
                        ) +
                        "   " +
                        this.buySellObj.order_action +
                        " " +
                        this.buySellObj.quantity +
                        "@" +
                        (res.price
                          ? Number(res.price).toFixed(2)
                          : Number(this.buySellObj.price).toFixed(2)) +
                        "    For " +
                        this.$helperService.getUpperCase(name_new) +
                        "(" +
                        code_new +
                        ")";
                      if (this.buySellObj.order_action == "SELL") {
                        this.$notify({
                          title: "SELL",
                          type: "error",
                          text: text_new,
                        });
                      }
                      if (this.buySellObj.order_action == "BUY") {
                        this.$notify({
                          title: "BUY",
                          type: "success",
                          text: text_new,
                        });
                      }
                    } else {
                      var text_new_2 =
                        "Intraday Close Order Placed Successfully " +
                        this.buySellObj.name_new +
                        "   " +
                        this.$helperService.getCustomDateYear(
                          this.buySellObj.expiry
                        ) +
                        // "   " +
                        // this.buySellObj.order_action +
                        // " " +
                        // this.buySellObj.quantity +
                        // "@" +
                        // Number(this.buySellObj.price).toFixed(2) +
                        "    For " +
                        this.$helperService.getUpperCase(name_new) +
                        "(" +
                        code_new +
                        ")";
                      this.$notify({
                        title: "",
                        type: "success",
                        text: text_new_2,
                      });
                      // if (this.buySellObj.order_action == "SELL") {
                      //   this.$notify({
                      //     title: "",
                      //     type: "error",
                      //     text: text_new_2,
                      //   });
                      // }
                      // if (this.buySellObj.order_action == "BUY") {

                      // }
                    }
                  }
                }
                this.$refs.buySell && this.$refs.buySell.resetForm();
                this.buySellObj.price = "";
                this.buySellObj.quantity = "";
                this.buySellObj.lot = "";
                this.buySellObj.user_id = "";
                this.reject_reason = "";
                this.buySellObj.reject_reason = "";
                this.buySellObj.order_action = "BUY";
                this.buySellObj.order_type = "MARKET";
                this.is_show_buy_sell_section = false;
              })
              .catch(() => {
                this.$refs.buySell && this.$refs.buySell.resetForm();
                this.buySellObj.price = "";
                this.buySellObj.quantity = "";
                this.buySellObj.lot = "";
                this.buySellObj.user_id = "";
                this.reject_reason = "";
                this.buySellObj.reject_reason = "";
                this.buySellObj.order_action = "BUY";
                this.buySellObj.order_type = "MARKET";
                this.is_show_buy_sell_section = false;
              });
          } else {
            this.$refs.buySell && this.$refs.buySell.resetForm();
            this.buySellObj.price = "";
            this.buySellObj.quantity = "";
            this.buySellObj.lot = "";
            this.buySellObj.user_id = "";
            this.reject_reason = "";
            this.buySellObj.reject_reason = "";
            this.buySellObj.order_action = "BUY";
            this.buySellObj.order_type = "MARKET";
            this.is_show_buy_sell_section = false;
          }
        });
    },
    closeBuySellPopup() {
      this.$refs.buySell && this.$refs.buySell.resetForm();
      this.buySellObj.price = "";
      this.buySellObj.quantity = "";
      this.buySellObj.lot = "";
      this.buySellObj.user_id = "";
      this.reject_reason = "";
      this.buySellObj.reject_reason = "";
      this.buySellObj.order_action = "BUY";
      this.buySellObj.order_type = "MARKET";
      this.is_show_buy_sell_section = false;
    },
    removeFromWishlist(id) {
      this.$api
        .putAPI({
          _action: "/remove-from-wishlist/" + id,
        })
        .then((res) => {
          if (res) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.message,
            });
            this.getCOMEXWishlist();
          }
        })
        .catch(() => {});
    },
    getClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          this.client_list = res;
          for (var i in this.client_list) {
            this.client_list[i].new_name =
              this.client_list[i].name + " (" + this.client_list[i].code + ")";
            if (this.role == "USER" && this.client_list[0]) {
              this.buySellObj.user_id = this.client_list[0].id;
            }
          }
        })
        .catch(() => {});
    },
    getScriptName(exchange) {
      this.detail.expire_token = "";
      this.detail.instrument_token = "";
      this.expireList = [];
      this.scriptList = [];
      if (exchange) {
        this.$api
          .getAPI({
            _action: "/script-list/" + exchange,
          })
          .then((res) => {
            this.scriptList = res;
          })
          .catch(() => {});
      }
    },
    getCOMEXWishlist(orderBy, sortBy) {
      this.$refs.errorComponent.updateFormLoader(true);
      var searchObj = {};
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/comex-wish-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.comex_wishlist = res;
          if (this.comex_wishlist.length > 0) {
            for (var i in this.comex_wishlist) {
              this.comex_wishlist[i].is_highlight = false;
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    emptyExpire() {
      this.detail.expire_token = "";
    },

    getScriptExpire(instrument_token) {
      this.$api
        .getAPI({
          _action: "/expire-list/" + instrument_token,
        })
        .then((res) => {
          this.expireList = res;
          for (var i in this.expireList) {
            if (this.expireList[i].expiry) {
              this.expireList[i].expiry = moment(
                this.expireList[i].expiry
              ).format("DD-MM-YYYY");
            }
          }
          if (this.expireList.length > 0) {
            this.detail.expire_token = this.expireList[0].instrument_token;
          }
        })
        .catch(() => {});
    },
    addToWishlist() {
      this.detail.exchange = 'COMEX';
      this.$api
        .postAPI({
          _action: "/add-wishlist",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.message,
            });
            this.getCOMEXWishlist();
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
