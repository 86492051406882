<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <!-- <h2
            class="text-start"
            style="color: rgb(29, 68, 91); font-weight: 600"
          >
            Default
          </h2> -->
          <form class="form-inline transparent-form p10 border-page m-t-2">
            <div class="col-lg-12 row">
              <div class="col-sm-2 mt-1">
                <Multiselect
                  mode="single"
                  trackBy="new_name"
                  label="new_name"
                  valueProp="id"
                  class="register-select"
                  placeholder=" Client"
                  v-model="searchObj.user_id"
                  :options="client_list"
                  :searchable="true"
                  @select="filterData()"
                />
              </div>
              <div class="col-sm-2 mt-1">
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="value"
                  class="register-select"
                  placeholder=" Exchange"
                  v-model="searchObj.exchange"
                  :options="exchanges"
                  :searchable="true"
                  @select="filterData()"
                />
              </div>
              <div class="col-sm-2 mt-1">
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="name"
                  class="register-select"
                  placeholder="Script"
                  v-model="searchObj.name"
                  :options="script_list"
                  :searchable="true"
                  @select="filterData()"
                />
              </div>
              <div class="col-lg-2 col-sm-3 mt-1">
                <datepicker
                  v-model="fromDate"
                  class="form-control"
                  :typeable="true"
                  inputFormat="dd-MM-yyyy"
                  placeholder="From Date"
                  style="width: 100% !important"
                />
              </div>
              <div class="col-lg-2 col-sm-3 mt-1">
                <datepicker
                  v-model="toDate"
                  class="form-control"
                  inputFormat="dd-MM-yyyy"
                  placeholder="To Date"
                  :typeable="true"
                  style="width: 100% !important"
                />
              </div>
              <div class="col-sm-2 text-right">
                <button
                  id="btn-search"
                  type="button"
                  v-on:click="getList(1)"
                  class="btn btn-primary mb-2"
                >
                  Search
                </button>
                &nbsp;
                <button
                  type="button"
                  v-on:click="reset()"
                  class="btn btn-secondary mb-2"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px"
      >
        <div class="col-md-12 bg-light border-page">
          <table
            id="table"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover"
          >
            <thead>
              <tr>
                <th class="head">D</th>
                <th class="head">Time</th>
                <th class="head">Client</th>
                <th class="head">Script</th>
                <th class="head">Type</th>
                <th class="head">Lot</th>
                <th class="head">Qty</th>
                <th class="head">Rate</th>
                <th class="head">Rejection Reason</th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr v-for="(data, index) in list" :key="index">
                <td
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                >
                  <i
                    v-if="data.device == 'WEB'"
                    class="fa fa-globe"
                    aria-hidden="true"
                  ></i>
                  <i
                    v-if="data.device == 'MOBILE'"
                    class="fa fa-mobile"
                    aria-hidden="true"
                  ></i>
                  <i
                    class="fa fa-user"
                    v-if="data.device == 'ADMIN'"
                    aria-hidden="true"
                  ></i>
                  <i
                    class="fa fa-user"
                    v-if="!data.device"
                    aria-hidden="true"
                  ></i>
                </td>
                <td
                  style="width: 10% !important"
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                >
                  {{
                    data.created_at
                      ? $helperService.getDateTime(data.created_at)
                      : ""
                  }}
                </td>

                <td
                  style="cursor: pointer"
                  :title="data.user ? data.user.name : ''"
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                >
                  {{
                    data.user
                      ? $helperService.getUpperCase(
                          data.user?.name + "-" + data.user?.code
                        )
                      : ""
                  }}
                </td>
                <td
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                  style="text-align: initial !important; width: 10% !important"
                >
                  {{ data.trading_symbol ? data.trading_symbol : "" }}
                </td>
                <td
                  style="width: 10% !important"
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                  :style="
                    data.order_action == 'BUY' ? 'color: blue;' : 'color: red;'
                  "
                >
                  {{
                    data.order_type == "MARKET"
                      ? data.order_action
                      : data.order_type == "INTRADAY"
                      ? "Intraday Close"
                      : data.order_action + " " + data.order_type
                  }}
                </td>
                <td
                  style="width: 10% !important"
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                >
                  {{ data.lot?.toFixed(2) }}
                </td>
                <td
                  style="width: 10% !important"
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                >
                  {{ data.quantity }}
                </td>
                <td
                  style="width: 10% !important"
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                >
                  {{ $helperService.getFormattedPrice(data.price) }}
                </td>
                <td
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                >
                  {{
                    data.reject_reason
                      ? $helperService.getUpperCase(data.reject_reason)
                      : ""
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(1)" ref="getList" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(1)"
      ref="deleteItem"
    />
  </div>
</template>
<script>
// import { Field } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Datepicker from "vue3-datepicker";
import Pagination from "@/components/Pagination";
import DeleteModal from "@/components/DeleteModal";
import moment from "moment";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    ErrorComponent,
    Multiselect,
    Datepicker,
    DeleteModal,
    Pagination,
  },
  name: "RejectionLog",
  data() {
    return {
      loader: true,
      type: "Default",
      list: [],
      fromDate: new Date(),
      toDate: new Date(),
      client_list : [],
      script_list: [],
      exchanges: [
        {
          name: "NSE",
          value: "NFO",
        },
        {
          name: "MCX",
          value: "MCX",
        },{
          name: "COMEX",
          value: "COMEX",
        },
      ],
      searchObj: {
        user_id: "",
        exchange: "",
        name: "",
      },
    };
  },
  mounted() {
    this.getList(1);
    this.getClientList();
    this.getScriptList(null, "FIRST");
  },
  methods: {
    reset() {
      this.searchObj = {};
      this.searchObj.user_id = "";
      this.searchObj.exchange = "";
      this.searchObj.name = "";
      this.getList(1);
    },
    getScriptList(exchange, type) {
      var searchObj = {};
      if (this.searchObj.exchange) {
        searchObj.exchange = exchange;
      }
      this.$api
        .getAPI({
          _action: "/script-list-by-exchange",
          _body: searchObj,
        })
        .then((res) => {
          this.script_list = res;
          if (!type) {
            this.filterData();
          }
        })
        .catch(() => {});
    },
    filterData() {
      this.getList(1);
    },
    getClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          this.client_list = [];
          for (var i in res) {
            this.client_list.push({...res[i], new_name :
              res[i].name + " (" + res[i].code + ")"})
          }
          
        })
        .catch(() => {});
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(1, obj.order_by, obj.sort_by);
    },
    getList(page) {
      this.loader = true;
      this.$refs.getList.updateListLoader(true);
      var searchObj = {};
      searchObj.trade_status = "REJECTED";
      if (this.searchObj.user_id) {
        searchObj.user_id = this.searchObj.user_id;
      }
      if (this.searchObj.exchange) {
        searchObj.exchange = this.searchObj.exchange;
      }
      if (this.searchObj.name) {
        searchObj.name = this.searchObj.name;
      }
      if (this.fromDate) {
        searchObj.from_date = moment(this.fromDate).format("yyyy-MM-DD");
      }

      if (this.toDate) {
        searchObj.to_date = moment(this.toDate).format("yyyy-MM-DD");
      }

      searchObj.order_by = "desc";
      searchObj.sort_by = "created_at";
      searchObj.offset = page - 1;
      searchObj.limit = 50;
      this.$api
        .getAPI({
          _action: "/trades-pagination-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.count = res.count;
          if (page == 1) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count, 50);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }

          if (this.$refs.getList) {
            this.$refs.getList.setData(res, "No Data available");
            this.$refs.getList.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.getList) {
            this.$refs.getList.setData(0, undefined, "");
            this.$refs.getList.updateListLoader(false);
          }
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
