<template>
  <div>
    <!-- <div id="chart">
    <apexchart
      width="1400"
      type="candlestick"
      :options="options"
      :series="options.series"></apexchart>
  </div> -->
    <div class="container form-signin3">
      <div class="row">
        <div
          class="col-md-12 row mb-3"
          style="padding-left: 50px; padding-right: 50px">
          <!-- <h2
            class="text-start"
            style="color: rgb(29, 68, 91); font-weight: 600"
          >
            Summary Report
          </h2> -->
          <ul class="col-6 nav nav-pills mb-2">
            <a
              v-if="this.$store.getters.getAccess?.is_home"
              style="
                font-size: 14px;
                line-height: 11px !important;
                text-align: center;
                display: inline flow-root list-item;
                cursor: pointer;
              "
              class="nav-link box-border-tab"
              :class="{ active: currentTab == 'DASHBOARD' }"
              v-on:click="changeTab('DASHBOARD')">
              <span style="">Dashboard</span></a
            >
            <a
              v-if="this.$store.getters.getAccess?.is_summary"
              style="
                font-size: 14px;
                line-height: 11px !important;
                cursor: pointer;
                width: 102px !important;
              "
              class="nav-link box-border-tab"
              :class="{ active: currentTab == 'SUMMARY' }"
              v-on:click="changeTab('SUMMARY')">
              <span style="color: black">Summary</span></a
            >
            <a
              v-if="this.$store.getters.getAccess?.is_user"
              style="
                font-size: 14px;
                line-height: 11px !important;
                cursor: pointer;
                width: 102px !important;
              "
              class="nav-link box-border-tab"
              :class="{ active: currentTab == 'USER' }"
              v-on:click="changeTab('USER')">
              <span style="color: black">USER</span></a
            >
          </ul>

          <div class="col-sm-2 mt-1">
            <Multiselect
              mode="single"
              trackBy="new_name"
              label="new_name"
              valueProp="id"
              class="register-select"
              placeholder="Client"
              v-model="searchObj.user_id"
              :options="client_list"
              searchable="true"
              @select="filterData()" />
          </div>
          <div class="col-sm-2 mt-1">
            <Multiselect
              mode="single"
              trackBy="name"
              label="name"
              valueProp="value"
              class="register-select"
              placeholder="Segment"
              v-model="searchObj.exchange"
              :options="exchanges"
              searchable="true"
              @select="filterData()" />
          </div>
          <div class="col-sm-2 mt-1">
            <Multiselect
              mode="single"
              trackBy="name"
              label="name"
              valueProp="instrument_token"
              class="register-select"
              placeholder="Script"
              v-model="searchObj.instrument_token"
              :options="script_list"
              searchable="true"
              @select="filterData()" />
          </div>
        </div>
      </div>
      <div class="col-12 row" style="margin-left: 12px">
        <div class="col-lg-6 border-page">
          <div class="col-12 row">
            <div class="col-8" style="justify-content: end; display: flex">
              <h5
                class="text-start"
                style="
                  color: rgb(29, 68, 91);
                  font-weight: 501;
                  font-size: 14px;
                ">
                RUNNING ORDERS
              </h5>
            </div>
            <div
              class="col-4"
              style="justify-content: end; display: flex; text-decoration: ">
              <a
                v-on:click="redirectTo('/orders')"
                style="
                  color: #0a90f3;
                  cursor: pointer;
                  text-decoration: underline;
                ">
                View All</a
              >
            </div>
          </div>
          <div
            class="col-md-12 bg-light text-center sticky-table-header"
            style="height: 329px">
            <table
              id="table"
              style="margin-top: 0px; margin-bottom: 0px"
              data-toggle="table"
              data-search="true"
              data-filter-control="true"
              data-toolbar="#toolbar"
              class="table table-sm text-center text-white table-bordered product-list table-hover">
              <thead>
                <tr>
                  <th class="head">D</th>
                  <th class="head">Time</th>
                  <th class="head">Client</th>
                  <th class="head">Script</th>
                  <th class="head">Type</th>
                  <th class="head">Lot</th>
                  <th class="head">Qty</th>
                  <th class="head">Rate</th>
                </tr>
              </thead>
              <tbody v-show="!isRunningTrades">
                <tr v-for="(data, index) in runningTrades" :key="index">
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    <i
                      v-if="data.device == 'WEB'"
                      class="fa fa-globe"
                      aria-hidden="true"></i>
                    <i
                      v-if="data.device == 'MOBILE'"
                      class="fa fa-mobile"
                      aria-hidden="true"></i>
                    <i
                      class="fa fa-user"
                      v-if="data.device == 'ADMIN'"
                      aria-hidden="true"></i>
                    <i
                      class="fa fa-user"
                      v-if="!data.device"
                      aria-hidden="true"></i>
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                      data.updated_at
                        ? $helperService.getTime(data.updated_at)
                        : $helperService.getTime(data.created_at)
                    }}
                  </td>

                  <td
                    style="cursor: pointer"
                    :title="data.name ? data.name : ''"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                       $helperService.getUpperCase(data?.name + "-" + data?.code)
                    }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    style="text-align: initial !important">
                    {{ data.trading_symbol ? data.trading_symbol : "" }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    :style="
                      data.order_action == 'BUY'
                        ? 'color: blue;'
                        : 'color: red;'
                    ">
                    {{
                      data.order_type == "MARKET"
                        ? data.order_action
                        : data.order_type == "INTRADAY"
                        ? "Intraday Close"
                        : data.order_action + " " + data.order_type
                    }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.lot?.toFixed(2) }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.quantity }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ $helperService.getFormattedPrice(data.price) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <ErrorComponent @retry="getRunningTrades('')" ref="runnungTrades" />
          </div>
        </div>
        <div class="col-lg-6 border-page">
          <div class="col-12 row">
            <div class="col-7" style="justify-content: end; display: flex">
              <h5
                class="text-start"
                style="
                  color: rgb(29, 68, 91);
                  font-weight: 501;
                  font-size: 14px;
                ">
                TRADES
              </h5>
            </div>
            <div
              class="col-5"
              style="justify-content: end; display: flex; text-decoration: ">
              <a
                v-on:click="redirectTo('/trades')"
                style="
                  color: #0a90f3;
                  cursor: pointer;
                  text-decoration: underline;
                ">
                View All</a
              >
            </div>
          </div>

          <div
            class="col-md-12 bg-light text-center sticky-table-header"
            style="height: 329px">
            <table
              id="table"
              style="margin-top: 0px; margin-bottom: 0px"
              data-toggle="table"
              data-search="true"
              data-filter-control="true"
              data-toolbar="#toolbar"
              class="table table-sm text-center text-white table-bordered product-list table-hover">
              <thead>
                <tr>
                  <th class="head">D</th>
                  <!-- <th class="head">O-Time</th> -->
                  <th class="head">Time</th>
                  <th class="head">Client</th>
                  <th class="head">Script</th>
                  <th class="head">Type</th>
                  <th class="head">I/O</th>
                  <th class="head">Lot</th>
                  <th class="head">Qty</th>
                  <th class="head">Rate</th>
                </tr>
              </thead>
              <tbody v-show="!isTrades">
                <tr v-for="(data, index) in trades" :key="index">
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    <i
                      v-if="data.device == 'WEB'"
                      class="fa fa-globe"
                      aria-hidden="true"></i>
                    <i
                      v-if="data.device == 'MOBILE'"
                      class="fa fa-mobile"
                      aria-hidden="true"></i>
                    <i
                      class="fa fa-user"
                      v-if="data.device == 'ADMIN'"
                      aria-hidden="true"></i>
                    <i
                      class="fa fa-user"
                      v-if="!data.device"
                      aria-hidden="true"></i>
                  </td>
                  <!-- <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                  >
                    {{
                      data.created_at
                        ? $helperService.getTime(data.created_at)
                        : ""
                    }}
                  </td> -->
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                      data.trade_date
                        ? $helperService.getTime(data.trade_date)
                        : ""
                    }}
                  </td>

                  <td
                    style="cursor: pointer"
                    :title="data.name ? data.name : ''"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                       $helperService.getUpperCase(data?.name + "-" + data?.code)
                    }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    style="text-align: initial !important">
                    {{ data.trading_symbol ? data.trading_symbol : "" }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    :style="
                      data.order_action == 'BUY'
                        ? 'color: blue;'
                        : 'color: red;'
                    ">
                    {{
                      data.order_type == "MARKET"
                        ? data.order_action
                        : data.order_type == "INTRADAY"
                        ? "Intraday Close"
                        : data.order_action + " " + data.order_type
                    }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.new_type ? data.new_type : "-" }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.lot?.toFixed(2) }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.quantity }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ $helperService.getFormattedPrice(data.price) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <ErrorComponent @retry="getTrades()" ref="errorComponent" />
          </div>
        </div>
      </div>
      <div class="col-12 row mt-3" style="margin-left: 12px">
        <div class="col-lg-12 border-page mt-3">
          <div class="col-12 row">
            <div class="col-7" style="justify-content: end; display: flex">
              <h5
                class="text-start"
                style="
                  color: rgb(29, 68, 91);
                  font-weight: 501;
                  font-size: 14px;
                ">
                REJECTED ORDERS
              </h5>
            </div>
            <div
              class="col-5"
              style="justify-content: end; display: flex; text-decoration: ">
              <a
                v-on:click="redirectTo('/rejection-log')"
                style="
                  color: #0a90f3;
                  cursor: pointer;
                  text-decoration: underline;
                ">
                View All</a
              >
            </div>
          </div>

          <div
            class="col-md-12 bg-light text-center sticky-table-header"
            style="height: 329px">
            <table
              id="table"
              style="margin-top: 0px; margin-bottom: 0px"
              data-toggle="table"
              data-search="true"
              data-filter-control="true"
              data-toolbar="#toolbar"
              class="table table-sm text-center text-white table-bordered product-list table-hover">
              <thead>
                <tr>
                  <th class="head">D</th>
                  <th class="head">Time</th>
                  <th class="head">Client</th>
                  <th class="head">Script</th>
                  <th class="head">Type</th>
                  <th class="head">Lot</th>
                  <th class="head">Qty</th>
                  <th class="head">Rate</th>
                  <th class="head">Rejection Reason</th>
                </tr>
              </thead>
              <tbody v-show="!isRejectedOrders">
                <tr v-for="(data, index) in rejectedOrders" :key="index">
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    <i
                      v-if="data.device == 'WEB'"
                      class="fa fa-globe"
                      aria-hidden="true"></i>
                    <i
                      v-if="data.device == 'MOBILE'"
                      class="fa fa-mobile"
                      aria-hidden="true"></i>
                    <i
                      class="fa fa-user"
                      v-if="data.device == 'ADMIN'"
                      aria-hidden="true"></i>
                    <i
                      class="fa fa-user"
                      v-if="!data.device"
                      aria-hidden="true"></i>
                  </td>
                  <td
                    style="width: 40px !important"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                      data.created_at
                        ? $helperService.getTime(data.created_at)
                        : ""
                    }}
                  </td>

                  <td
                    style="cursor: pointer"
                    :title="data.name ? data.name : ''"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                      $helperService.getUpperCase(data?.name + "-" + data?.code)
                    }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    style="
                      text-align: initial !important;
                      width: 10% !important;
                    ">
                    {{ data.trading_symbol ? data.trading_symbol : "" }}
                  </td>
                  <td
                    style="width: 120px !important"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    :style="
                      data.order_action == 'BUY'
                        ? 'color: blue;'
                        : 'color: red;'
                    ">
                    {{
                      data.order_type == "MARKET"
                        ? data.order_action
                        : data.order_type == "INTRADAY"
                        ? "Intraday Close"
                        : data.order_action + " " + data.order_type
                    }}
                  </td>
                  <td
                    style="width: 60px !important"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.lot?.toFixed(2) }}
                  </td>
                  <td
                    style="width: 60px !important"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.quantity }}
                  </td>
                  <td
                    style="width: 60px !important"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ $helperService.getFormattedPrice(data.price) }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                      data.reject_reason
                        ? $helperService.getUpperCase(data.reject_reason)
                        : ""
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <ErrorComponent
              @retry="getRejectedOrders('')"
              ref="getRejectedOrders" />
          </div>
        </div>
      </div>
      <div class="col-12 row mt-3" style="margin-left: 12px">
        <div class="col-lg-6 border-page">
          <div class="col-12 row">
            <div class="col-8" style="justify-content: end; display: flex">
              <h5
                class="text-start"
                style="
                  color: rgb(29, 68, 91);
                  font-weight: 501;
                  font-size: 14px;
                ">
                EXECUTED ORDERS
              </h5>
            </div>
            <div
              class="col-4"
              style="justify-content: end; display: flex; text-decoration: ">
              <a
                v-on:click="redirectTo('/orders?type=COMPLETED')"
                style="
                  color: #0a90f3;
                  cursor: pointer;
                  text-decoration: underline;
                ">
                View All</a
              >
            </div>
          </div>
          <div
            class="col-md-12 bg-light text-center sticky-table-header"
            style="height: 329px">
            <table
              id="table"
              style="margin-top: 0px; margin-bottom: 0px"
              data-toggle="table"
              data-search="true"
              data-filter-control="true"
              data-toolbar="#toolbar"
              class="table table-sm text-center text-white table-bordered product-list table-hover">
              <thead>
                <tr>
                  <th class="head">D</th>
                  <th class="head">O-Time</th>
                  <th class="head">T-Time</th>
                  <th class="head">Client</th>
                  <th class="head">Script</th>
                  <th class="head">Type</th>
                  <th class="head">Lot</th>
                  <th class="head">Qty</th>
                  <th class="head">Rate</th>
                </tr>
              </thead>
              <tbody v-show="!isExecutedOrders">
                <tr v-for="(data, index) in executedOrders" :key="index">
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    <i
                      v-if="data.device == 'WEB'"
                      class="fa fa-globe"
                      aria-hidden="true"></i>
                    <i
                      v-if="data.device == 'MOBILE'"
                      class="fa fa-mobile"
                      aria-hidden="true"></i>
                    <i
                      class="fa fa-user"
                      v-if="data.device == 'ADMIN'"
                      aria-hidden="true"></i>
                    <i
                      class="fa fa-user"
                      v-if="!data.device"
                      aria-hidden="true"></i>
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                      data.created_at
                        ? $helperService.getTime(data.created_at)
                        : ""
                    }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                      data.trade_date
                        ? $helperService.getTime(data.trade_date)
                        : "-"
                    }}
                  </td>

                  <td
                    style="cursor: pointer"
                    :title="data.name ? data.name : ''"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                       $helperService.getUpperCase(data?.name + "-" + data?.code)
                    }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    style="text-align: initial !important">
                    {{ data.trading_symbol ? data.trading_symbol : "" }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    :style="
                      data.order_action == 'BUY'
                        ? 'color: blue;'
                        : 'color: red;'
                    ">
                    {{
                      data.order_type == "MARKET"
                        ? data.order_action
                        : data.order_type == "INTRADAY"
                        ? "Intraday Close"
                        : data.order_action + " " + data.order_type
                    }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.lot?.toFixed(2) }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.quantity }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ $helperService.getFormattedPrice(data.price) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <ErrorComponent
              @retry="getExecutedOrders(0)"
              ref="ExecutedOrders" />
          </div>
        </div>
        <div class="col-lg-6 border-page">
          <div class="col-12 row">
            <div class="col-8" style="justify-content: end; display: flex">
              <h5
                class="text-start"
                style="
                  color: rgb(29, 68, 91);
                  font-weight: 501;
                  font-size: 14px;
                ">
                M2M ALERT
              </h5>
            </div>
            <div
              class="col-4"
              style="
                justify-content: end;
                display: flex;
                text-decoration: ;
              "></div>
          </div>
          <div
            class="col-md-12 bg-light text-center sticky-table-header"
            style="height: 329px">
            <table
              id="table"
              style="margin-top: 0px; margin-bottom: 0px"
              data-toggle="table"
              data-search="true"
              data-filter-control="true"
              data-toolbar="#toolbar"
              class="table table-sm text-center text-white table-bordered product-list table-hover">
              <thead>
                <tr>
                  <th class="head">Client</th>
                  <th class="head">Ledger Bal.</th>
                  <th class="head">Loss Alert(%)</th>
                  <th class="head">M2M</th>
                  <!-- <th class="head">Time</th> -->
                </tr>
              </thead>
              <tbody v-show="!isM2MAlerts">
                <tr v-for="(data, index) in m2MAlerts" :key="index">
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                      data.name ? $helperService.getUpperCase(data.name) : ""
                    }}
                  </td>

                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ $helperService.getFormattedPrice(data.ledger) }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ $helperService.getFormattedPrice(data.percentage) }}%
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ $helperService.getFormattedPrice(data.m2m) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <ErrorComponent ref="getM2MAlerts" />
          </div>
        </div>
      </div>
      <div class="col-lg-12 border-page mt-3">
        <div class="col-12 row">
          <div class="col-7" style="justify-content: end; display: flex">
            <h5
              class="text-start"
              style="color: rgb(29, 68, 91); font-weight: 501; font-size: 14px">
              FRESH ORDERS
            </h5>
          </div>
          <div
            class="col-5"
            style="
              justify-content: end;
              display: flex;
              text-decoration: ;
            "></div>
        </div>
        <div
          class="col-md-12 bg-light text-center sticky-table-header"
          style="height: 329px">
          <table
            id="table"
            style="margin-top: 0px; margin-bottom: 0px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th class="head">D</th>
                <th class="head">Time</th>
                <th class="head">Client</th>
                <th class="head">Script</th>
                <th class="head">Type</th>
                <th class="head">Lot</th>
                <th class="head">Qty</th>
                <th class="head">Rate</th>
              </tr>
            </thead>
            <tbody v-show="!isFreshTrades">
              <tr v-for="(data, index) in freshTrades" :key="index">
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  <i
                    v-if="data.device == 'WEB'"
                    class="fa fa-globe"
                    aria-hidden="true"></i>
                  <i
                    v-if="data.device == 'MOBILE'"
                    class="fa fa-mobile"
                    aria-hidden="true"></i>
                  <i
                    class="fa fa-user"
                    v-if="data.device == 'ADMIN'"
                    aria-hidden="true"></i>
                  <i
                    class="fa fa-user"
                    v-if="!data.device"
                    aria-hidden="true"></i>
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{
                    data.created_at
                      ? $helperService.getTime(data.created_at)
                      : ""
                  }}
                </td>

                <td
                  style="cursor: pointer"
                  :title="data.name ? data.name : ''"
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{
                    $helperService.getUpperCase(data?.name + "-" + data?.code)
                  }}
                </td>
                <td
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                  style="text-align: initial !important">
                  {{ data.trading_symbol ? data.trading_symbol : "" }}
                </td>
                <td
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                  :style="
                    data.order_action == 'BUY' ? 'color: blue;' : 'color: red;'
                  ">
                  {{
                    data.order_type == "MARKET"
                      ? data.order_action
                      : data.order_type == "INTRADAY"
                      ? "Intraday Close"
                      : data.order_action + " " + data.order_type
                  }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.lot?.toFixed(2) }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.quantity }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ $helperService.getFormattedPrice(data.price) }}
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getFreshTrades(0)" ref="freshTrades" />
        </div>
      </div>
    </div>
  </div>
  <audio v-show="false" id="audio">
    <source
      src="https://www.soundjay.com/buttons/sounds/beep-01a.mp3"
      type="audio/ogg" />
  </audio>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Multiselect from "@vueform/multiselect";
import webstomp from "webstomp-client";

export default {
  components: {
    ErrorComponent,
    Multiselect,
  },
  name: "Dashboard",
  data() {
    return {
      role: localStorage.getItem("role"),
      isRunningTrades: true,
      myChart: undefined,
      isFreshTrades: true,
      isTrades: true,
      client: undefined,
      isExecutedOrders: true,
      isM2MAlerts: true,
      isRejectedOrders: true,
      searchObj: {
        user_id: "",
        exchange: "",
        name: "",
      },
      currentTab: "DASHBOARD",
      exchanges: [
        {
          name: "NSE",
          value: "NFO",
        },
        {
          name: "MCX",
          value: "MCX",
        },{
          name: "COMEX",
          value: "COMEX",
        },
      ],
      runningTrades: [],
      freshTrades: [],
      trades: [],
      executedOrders: [],
      client_list: [],
      script_list: [],
      orderList: [],
      m2MAlerts: [],
      chartData: [],
      rejectedOrders: [],
      options: {
        series: [],
      },
    };
  },
  computed: {
    getClientIds() {
      return JSON.parse(localStorage.getItem("client_ids") || "[]");
    },
    isNotification() {
      return this.$store.getters.getUser?.is_notification;
    },
  },
  mounted() {
    this.getClientList();
    this.getScriptList(null, "FIRST");
    this.refresh();
    this.connect();
    var $this = this;
    setInterval(() => {
      $this.checkConnection();
    }, 5000);
    this.access = this.$store.getters.getAccess;
  },
  methods: {
    getScriptList(exchange, type) {
      var searchObj = {};
      if (this.searchObj.exchange) {
        searchObj.exchange = exchange;
      }
      this.$api
        .getAPI({
          _action: "/script-list-by-exchange",
          _body: searchObj,
        })
        .then((res) => {
          this.script_list = res;
          if (!type) {
            this.filterData();
          }
        })
        .catch(() => {});
    },
    redirectTo(url) {
      this.$router.push(url);
    },
    getClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          this.client_list = res;
          for (var i in this.client_list) {
            this.client_list[i].new_name =
              this.client_list[i].name + " (" + this.client_list[i].code + ")";
          }
        })
        .catch(() => {});
    },
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "SUMMARY") {
        this.$router.push("/summary-report");
      }
      if (tab == "USER") {
        this.$router.push("/ip");
      }
    },
    filterOnSettimeData() {
      this.getRunningTrades("NO_LOADER");
      this.getTrades("NO_LOADER");
      this.getExecutedOrders("NO_LOADER");
      this.getRejectedOrders("NO_LOADER");
    },
    filterData() {
      this.isRunningTrades = true;
      this.isTrades = true;
      this.isExecutedOrders = true;
      this.isFreshTrades = true;
      this.isM2MAlerts = true;
      this.isRejectedOrders = true;
      this.getRunningTrades();
      this.getFreshTrades();
      this.getTrades();
      this.getExecutedOrders();
      this.getRejectedOrders();
    },
    getFreshTrades(type) {
      if (window.location.pathname != "/dashboard") {
        return;
      }

      if (!type) {
        this.$refs.freshTrades.updateListLoader(true);
      }
      var searchObj = {};
      searchObj.trade_status = "PENDING";
      searchObj.order_type = "LIMIT,STOPLOSS,INTRADAY";
      if (this.searchObj.user_id) {
        searchObj.user_id = this.searchObj.user_id;
      }
      if (this.searchObj.exchange) {
        searchObj.exchange = this.searchObj.exchange;
      }
      if (this.searchObj.instrument_token) {
        searchObj.instrument_token = this.searchObj.instrument_token;
      }
      if (this.searchObj.name) {
        searchObj.name = this.searchObj.name;
      }
      searchObj.day = "TODAY";
      searchObj.is_fresh_order = 1;
      this.$api
        .getAPI({
          _action: "/today_trades_view",
          _body: searchObj,
        })
        .then((res) => {
          this.isFreshTrades = false;
          this.freshTrades = res;
          res.list = this.freshTrades;
          // for (var i in this.freshTrades) {
          //   var type = this.freshTrades[i].type.split(",");
          //   this.freshTrades[i].new_type = type[0];
          //   this.freshTrades[i].type = type[1];
          // }
          if (this.$refs.freshTrades) {
            this.$refs.freshTrades.setData(res, "No Data available");
            this.$refs.freshTrades.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.freshTrades) {
            this.$refs.freshTrades.setData(0, undefined, "");
            this.$refs.freshTrades.updateListLoader(false);
          }
        });
    },
    getRunningTrades(type) {
      if (window.location.pathname != "/dashboard") {
        return;
      }
      if (!type) {
        this.$refs.runnungTrades.updateListLoader(true);
      }
      var searchObj = {};
      searchObj.trade_status = "PENDING";
      searchObj.order_type = "LIMIT,STOPLOSS,INTRADAY";
      if (this.searchObj.user_id) {
        searchObj.user_id = this.searchObj.user_id;
      }
      if (this.searchObj.exchange) {
        searchObj.exchange = this.searchObj.exchange;
      }
      if (this.searchObj.instrument_token) {
        searchObj.instrument_token = this.searchObj.instrument_token;
      }
      if (this.searchObj.name) {
        searchObj.name = this.searchObj.name;
      }
      searchObj.order_by = "desc";
      searchObj.sort_by = "updated_at";
      searchObj.day = "TODAY";
      this.$api
        .getAPI({
          _action: "/today_trades_view",
          _body: searchObj,
        })
        .then((res) => {
          this.isRunningTrades = false;
          this.runningTrades = res;
          res.list = this.runningTrades;
          // for (var i in this.runningTrades) {
          //   var type = this.runningTrades[i].type.split(",");
          //   this.runningTrades[i].new_type = type[0];
          //   this.runningTrades[i].type = type[1];
          // }
          if (this.$refs.runnungTrades) {
            this.$refs.runnungTrades.setData(res, "No Data available");
            this.$refs.runnungTrades.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.runnungTrades) {
            this.$refs.runnungTrades.setData(0, undefined, "");
            this.$refs.runnungTrades.updateListLoader(false);
          }
        });
    },
    getTrades(type) {
      if (window.location.pathname != "/dashboard") {
        return;
      }

      if (!type) {
        this.$refs.runnungTrades.updateListLoader(true);
      }
      var searchObj = {};
      searchObj.trade_status = "EXECUTED";
      searchObj.day = "TODAY";
      if (this.searchObj.user_id) {
        searchObj.user_id = this.searchObj.user_id;
      }
      if (this.searchObj.exchange) {
        searchObj.exchange = this.searchObj.exchange;
      }
      if (this.searchObj.instrument_token) {
        searchObj.instrument_token = this.searchObj.instrument_token;
      }
      if (this.searchObj.name) {
        searchObj.name = this.searchObj.name;
      }
      searchObj.sort_by = "trade_date";
      searchObj.order_by = "desc";
      this.$api
        .getAPI({
          _action: "/today_trades_view",
          _body: searchObj,
        })
        .then((res) => {
          this.isTrades = false;
          this.trades = [];
          for (var i in res) {
            // var type = res[i].type.split(",");
            // res[i].new_type = type[0];
            // res[i].type = type[1];
            if (res[i].type != "CF") {
              this.trades.push(res[i]);
            }
          }

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              { list: this.trades },
              "No Data available"
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    getExecutedOrders(type) {
      if (window.location.pathname != "/dashboard") {
        return;
      }

      if (!type) {
        this.$refs.ExecutedOrders.updateListLoader(true);
      }
      var searchObj = {};
      searchObj.trade_status = "EXECUTED";
      searchObj.order_type = "LIMIT,STOPLOSS";
      searchObj.day = "TODAY";
      if (this.searchObj.user_id) {
        searchObj.user_id = this.searchObj.user_id;
      }
      if (this.searchObj.exchange) {
        searchObj.exchange = this.searchObj.exchange;
      }
      if (this.searchObj.instrument_token) {
        searchObj.instrument_token = this.searchObj.instrument_token;
      }
      if (this.searchObj.name) {
        searchObj.name = this.searchObj.name;
      }
      this.$api
        .getAPI({
          _action: "/today_trades_view",
          _body: searchObj,
        })
        .then((res) => {
          if (type == "FALSE") {
            if (this.executedOrders.length != res.length) {
              this.playBeep();
            }
          }

          this.isExecutedOrders = false;
          this.executedOrders = res;
          // for (var i in this.executedOrders) {
          //   var type = this.executedOrders[i].type.split(",");
          //   this.executedOrders[i].new_type = type[0];
          //   this.executedOrders[i].type = type[1];
          // }
          if (this.$refs.ExecutedOrders) {
            this.$refs.ExecutedOrders.setData(
              { list: res },
              "No Data available"
            );
            this.$refs.ExecutedOrders.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.ExecutedOrders) {
            this.$refs.ExecutedOrders.setData(0, undefined, "");
            this.$refs.ExecutedOrders.updateListLoader(false);
          }
        });
    },
    getRejectedOrders(type) {
      if (window.location.pathname != "/dashboard") {
        return;
      }

      if (!type) {
        this.$refs.getRejectedOrders.updateListLoader(true);
      }
      var searchObj = {};
      searchObj.trade_status = "REJECTED";
      searchObj.day = "TODAY";
      searchObj.order_by = "desc";
      searchObj.sort_by = "created_at";
      if (this.searchObj.user_id) {
        searchObj.user_id = this.searchObj.user_id;
      }
      if (this.searchObj.exchange) {
        searchObj.exchange = this.searchObj.exchange;
      }
      if (this.searchObj.instrument_token) {
        searchObj.instrument_token = this.searchObj.instrument_token;
      }
      if (this.searchObj.name) {
        searchObj.name = this.searchObj.name;
      }

      this.$api
        .getAPI({
          _action: "/today_trades_view",
          _body: searchObj,
        })
        .then((res) => {
          if (type == "FALSE") {
            if (this.rejectedOrders.length != res.length) {
              this.playBeep();
            }
          }

          this.isRejectedOrders = false;
          this.rejectedOrders = res;
          // for (var i in this.rejectedOrders) {
          //   var type = this.rejectedOrders[i].type.split(",");
          //   this.rejectedOrders[i].new_type = type[0];
          //   this.rejectedOrders[i].type = type[1];
          // }
          if (this.$refs.getRejectedOrders) {
            this.$refs.getRejectedOrders.setData(
              { list: this.rejectedOrders },
              "No Data available"
            );
            this.$refs.getRejectedOrders.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.getRejectedOrders) {
            this.$refs.getRejectedOrders.setData(0, undefined, "");
            this.$refs.getRejectedOrders.updateListLoader(false);
          }
        });
    },
    checkConnection() {
      if (!this.client || !this.client.connected) {
        this.connect();
      }
    },

    connect() {
      var $this = this;
      this.client = webstomp.client(this.$api.url, { debug: false });
      this.client.connect(this.$api.username, this.$api.password, () => {
        this.client.subscribe("/exchange/m2m-alert", (message) => {
          if ($this.$refs.getM2MAlerts) {
            $this.$refs.getM2MAlerts.updateListLoader(true);
            $this.showData(JSON.parse(message.body));
          }
        });
        this.client.subscribe("/exchange/refresh-data", () => {
          $this.getRunningTrades("FALSE");
          $this.getFreshTrades("FALSE");
        });
        this.client.subscribe("/exchange/refresh-rejection", () => {
          $this.getRejectedOrders("FALSE");
        });
        this.client.subscribe("/exchange/refresh-trade", () => {
          $this.getTrades("FALSE");
          $this.getFreshTrades("FALSE");
        });
      });
    },
    showData(data) {
      if (this.$refs.getM2MAlerts) {
        this.$refs.getM2MAlerts.updateListLoader(true);
        this.m2MAlerts = [];
        for (var i in data) {
          for (var j in this.getClientIds) {
            if (data[i].user_id == this.getClientIds[j].id) {
              var obj = {
                name:
                  this.getClientIds[j].name +
                  " (" +
                  this.getClientIds[j].code +
                  ")",
                ledger: data[i].ledger,
                m2m: data[i].m2m,
                percentage: (Math.abs(data[i].m2m) / data[i].ledger) * 100,
              };
              this.m2MAlerts.push(obj);
              this.isM2MAlerts = false;
              var res = {};
              res.list = this.m2MAlerts;
              if (this.$refs.getM2MAlerts) {
                this.$refs.getM2MAlerts.setData(res, "No Data available");
                this.$refs.getM2MAlerts.updateListLoader(false);
              }
            }
          }
        }
      }
      if (this.m2MAlerts.length == 0 && this.$refs.getM2MAlerts) {
        this.$refs.getM2MAlerts.updateListLoader(false);
      }
    },
    playBeep() {
      if (this.isNotification == 1) {
        var sound = document.getElementById("audio");
        sound.play();
      }
    },
    refresh() {
      this.getRunningTrades("");
      this.getTrades("");
      this.getExecutedOrders("");
      this.getRejectedOrders("");
      this.getFreshTrades("");
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
